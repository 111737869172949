import React, { useState } from 'react';

import * as mixpanel from '../../../../../mixpanel';

import {
  COMMUNITY_DASHBOARD_FILTER_TYPE,
  FILTER_RELATIONS
} from '../../../CommunityDashboard/helpers/constants';
import transFormFiltersForMixpanel from '../../helpers/mixpanel/transFormFiltersForMixpanel';

import AppBar from '../../../../base/components/AppBar/AppBar';
import TotalSampleSize from './components/TotalSampleSize/TotalSampleSize';
import Filters from '../../../CommunityDashboard/components/CommunityDashboardPage/components/Filters/Filters';
import MainPageCharts from './components/MainPageCharts/MainPageCharts';

import styles from './FeasibilityCheckerPage.module.css';
import { checkIfAllCookiesAllowed } from '../../../../helpers/privacy';

export default props => {
  const { isAdmin } = props;
  const [appliedFilters, setAppliedFilters] = useState([
    {
      type: 'USER_PROPERTY',
      userProperties: {
        propertyName: 'LANGUAGE',
        propertyValue: ['Dutch']
      }
    }
  ]);
  const [filterRelation, setFilterRelation] = useState(FILTER_RELATIONS.AND);
  const [isGlobalLoading, setIsGlobalLoading] = useState(false);
  const [totalSampleSize, setTotalSampleSize] = useState(300);
  const [totalSampleSizeLoading, setTotalSampleSizeLoading] = useState(false);
  const [minimumQuotaEnabled, setMinimumQuotaEnabled] = useState(false);

  const trackMixpanelEvent = (event, filters) => {
    const mixPanelEventProperties = {
      [mixpanel.eventProperties.FEASIBLITY_CHECKER
        .TOTAL_SAMPLE_SIZE]: totalSampleSize,
      [mixpanel.eventProperties.FEASIBLITY_CHECKER
        .APPLIED_FILTERS]: transFormFiltersForMixpanel(filters),
      [mixpanel.eventProperties.FEASIBLITY_CHECKER
        .FILTER_RELATION]: filterRelation
    };
    if (checkIfAllCookiesAllowed()) {
      mixpanel.actions.trackEvent(event, mixPanelEventProperties);
    }
  };

  if (!isAdmin) return null;

  return (
    <div>
      <AppBar {...props} />
      <div className={styles.container}>
        <div className={styles.title}>Feasibility checker</div>
        <TotalSampleSize
          totalSampleSize={totalSampleSize}
          setTotalSampleSize={setTotalSampleSize}
          setTotalSampleSizeLoading={setTotalSampleSizeLoading}
        />
        <Filters
          appliedFilters={appliedFilters}
          setAppliedFilters={setAppliedFilters}
          filterRelation={filterRelation}
          setFilterRelation={setFilterRelation}
          isGlobalLoading={isGlobalLoading}
          isFeasibilityChecker
          minimumQuotaEnabled={minimumQuotaEnabled}
          setMinimumQuotaEnabled={setMinimumQuotaEnabled}
          trackMixpanelEvent={trackMixpanelEvent}
          customHideFilterTypes={[COMMUNITY_DASHBOARD_FILTER_TYPE.ACQUISITION]}
        />
        <MainPageCharts
          appliedFilters={appliedFilters}
          filterRelation={filterRelation}
          isGlobalLoading={isGlobalLoading}
          setIsGlobalLoading={setIsGlobalLoading}
          totalSampleSize={totalSampleSize}
          totalSampleSizeLoading={totalSampleSizeLoading}
          minimumQuotaEnabled={minimumQuotaEnabled}
        />
      </div>
    </div>
  );
};
