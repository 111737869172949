import * as mixpanel from '../../../../../../../mixpanel';
import { checkIfAllCookiesAllowed } from '../../../../../../helpers/privacy';

export default (filterType, decisionFrameworkType) => {
  if (checkIfAllCookiesAllowed()) {
    mixpanel.actions.trackEvent(
      mixpanel.events.CLIENT_SET_LOVERS_HATERS_FILTER,
      {
        [mixpanel.eventProperties.FILTER_TYPE]: filterType,
        [mixpanel.eventProperties
          .DECISION_FRAMEWORK_TYPE]: decisionFrameworkType
      }
    );
  }
};
