import React, { useContext } from 'react';

import * as mixpanel from '../../../../../../../../../../mixpanel';

import TemplatesWizardContext from '../../../TemplatesWizardContext';
import Footer from '../../Footer/Footer';

import scratch from '../../../../../../../../../assets/img/templateswizard/scratch.svg';
import template from '../../../../../../../../../assets/img/templateswizard/template.svg';

import {
  TEMPLATES_WIZARD_STEP_KEYS,
  TEMPLATES_WIZARD_START_OPTIONS
} from '../../../../../helpers/constants';

import styles from './SelectStart.module.css';
import { checkIfAllCookiesAllowed } from '../../../../../../../../../helpers/privacy';

export default () => {
  const {
    currentStep,
    setStepValue,
    goToNextStep,
    setWizardHidden,
    strings,
    surveyId
  } = useContext(TemplatesWizardContext);

  const onNextClick = () => {
    if (currentStep.value === TEMPLATES_WIZARD_START_OPTIONS.SELECT_TEMPLATE) {
      if (checkIfAllCookiesAllowed()) {
        mixpanel.actions.trackEvent(
          mixpanel.events.CLIENT_CLICK_SELECT_A_TEMPLATE,
          {
            [mixpanel.eventProperties.SURVEY_ID]: surveyId
          }
        );
      }
      return goToNextStep();
    }
    if (currentStep.value === TEMPLATES_WIZARD_START_OPTIONS.START_SCRATCH) {
      setWizardHidden(true);
      if (checkIfAllCookiesAllowed()) {
        mixpanel.actions.trackEvent(
          mixpanel.events.CLIENT_CLICK_START_FROM_SCRATCH,
          {
            [mixpanel.eventProperties.SURVEY_ID]: surveyId
          }
        );
      }
    }
    return null;
  };

  return (
    <div className={styles.container}>
      <div className={styles.title}>
        {strings.WOULD_YOU_LIKE_TO_USE_TEMPLATE}
      </div>
      <div className={styles.optionsContainer}>
        <div
          className={styles.option}
          onClick={() =>
            setStepValue(
              TEMPLATES_WIZARD_STEP_KEYS.SELECT_START,
              TEMPLATES_WIZARD_START_OPTIONS.SELECT_TEMPLATE
            )
          }
          role="presentation"
        >
          <img src={template} alt="Select a template" />
          <div className={styles.optionLabel}>{strings.SELECT_TEMPLATE}</div>
          {currentStep &&
            currentStep.value ===
              TEMPLATES_WIZARD_START_OPTIONS.SELECT_TEMPLATE && (
              <div className={styles.selectedBar} />
            )}
        </div>
        <div
          className={styles.option}
          onClick={() =>
            setStepValue(
              TEMPLATES_WIZARD_STEP_KEYS.SELECT_START,
              TEMPLATES_WIZARD_START_OPTIONS.START_SCRATCH
            )
          }
          role="presentation"
        >
          <img src={scratch} alt="Start from scratch" />
          <div className={styles.optionLabel}>{strings.START_FROM_SCRATCH}</div>
          {currentStep &&
            currentStep.value ===
              TEMPLATES_WIZARD_START_OPTIONS.START_SCRATCH && (
              <div className={styles.selectedBar} />
            )}
        </div>
      </div>
      <div className={styles.footerContainer}>
        <Footer
          hiddenPrevious
          onNextClick={onNextClick}
          nextLabel={strings.NEXT}
          previousLabel={strings.PREVIOUS}
        />
      </div>
    </div>
  );
};
