import FileSaver from 'file-saver';
import { call, put, select, takeLatest, all } from 'redux-saga/effects';
import * as mixpanel from '../../mixpanel';
import * as actions from './actions';
import campaignFilterToString from './helpers/campaignFilterToString';
import { checkIfAllCookiesAllowed } from '../helpers/privacy';

const GetCampaigns = api =>
  function* getCampaigns(action) {
    try {
      const portal = yield select(state => state.campaigns.portal);
      const authState = yield select(state => state.auth);
      const clientStarredCampaigns = yield select(
        state => state.auth.clientStarredCampaigns
      );

      // In some cases prevent loading surveys before decide on source
      /*
      if (
        (authState.isAdmin &&
          authState.accountManagement &&
          !authState.accountManagement.selectedAccountManagementRecord) ||
        (!authState.isAdmin &&
          authState.accountManagement &&
          authState.accountManagement
            .multipleAccountManagementRecordsToSelect &&
          !authState.accountManagement.selectedAccountManagementRecord)
      ) {
        return;
      }
      */

      // Clients
      const clientData = yield call(api.getAllClients);
      const clientDict = clientData.clients.reduce((acc, el) => {
        acc[el.id] = el;
        return acc;
      }, {});

      // Other campaigns
      const otherCampaignsFilter = campaignFilterToString(
        {
          name: 'excludeIds',
          value:
            clientStarredCampaigns && clientStarredCampaigns.length
              ? clientStarredCampaigns.toString()
              : []
        },
        portal.filter
      );
      const otherCampaignsData = yield call(
        api.getCampaigns,
        authState.accountManagement.selectedAccountManagementRecord &&
          authState.accountManagement.selectedAccountManagementRecord.id
          ? authState.accountManagement.selectedAccountManagementRecord.id
          : null,
        action.page || action.page === 0
          ? action.page
          : portal.otherCampaigns.page,
        portal.otherCampaigns.rowsPerPage,
        otherCampaignsFilter,
        portal.otherCampaigns.sortColumn,
        portal.otherCampaigns.sortType
      );
      otherCampaignsData.campaigns.forEach(c => {
        const data = c;
        if (clientDict[c.client]) {
          data.client = clientDict[data.client];
        }
      });
      const otherCampaigns = {
        campaigns: otherCampaignsData.campaigns,
        total: otherCampaignsData.total,
        sortColumn: portal.otherCampaigns.sortColumn,
        sortType: portal.otherCampaigns.sortType,
        page:
          action.page || action.page === 0
            ? action.page
            : portal.otherCampaigns.page,
        rowsPerPage: portal.otherCampaigns.rowsPerPage
      };

      // Starred campaigns
      let starredCampaigns = {};
      if (clientStarredCampaigns && clientStarredCampaigns.length) {
        const starredCampaignsFilter = campaignFilterToString(
          {
            name: 'showIds',
            value: clientStarredCampaigns.toString()
          },
          portal.filter
        );
        const starredCampaignsData = yield call(
          api.getCampaigns,
          authState.accountManagement.selectedAccountManagementRecord &&
            authState.accountManagement.selectedAccountManagementRecord.id
            ? authState.accountManagement.selectedAccountManagementRecord.id
            : null,
          0,
          20,
          starredCampaignsFilter,
          portal.starredCampaigns.sortColumn,
          portal.starredCampaigns.sortType
        );
        starredCampaignsData.campaigns.forEach(c => {
          const data = c;
          if (clientDict[c.client]) {
            data.client = clientDict[data.client];
          }
        });
        starredCampaigns = {
          campaigns: starredCampaignsData.campaigns,
          total: starredCampaignsData.total,
          sortColumn: portal.starredCampaigns.sortColumn,
          sortType: portal.starredCampaigns.sortType
        };
      } else {
        starredCampaigns = {
          campaigns: [],
          total: 0,
          sortColumn: portal.starredCampaigns.sortColumn,
          sortType: portal.starredCampaigns.sortType
        };
      }

      yield put({
        type: actions.GET_CAMPAIGNS_SUCCEEDED,
        otherCampaigns,
        starredCampaigns,
        starredCampaignIds:
          clientStarredCampaigns && clientStarredCampaigns.length
            ? clientStarredCampaigns
            : [],
        availableClients: clientData.clients,
        filter: portal.filter
      });
    } catch (err) {
      yield put({ type: actions.GET_CAMPAIGNS_FAILED, error: err.message });
    }
  };

const CreateCampaign = api =>
  function* createCampaign(action) {
    try {
      const authState = yield select(state => state.auth);

      const campaign = yield call(
        api.createCampaign,
        authState.accountManagement.selectedAccountManagementRecord &&
          authState.accountManagement.selectedAccountManagementRecord.id
          ? authState.accountManagement.selectedAccountManagementRecord.id
          : null
      );
      campaign.type = 'survey';

      if (checkIfAllCookiesAllowed()) {
        mixpanel.actions.trackEvent(mixpanel.events.CLIENT_NEW_SURVEY, {
          [mixpanel.eventProperties.SURVEY_ID]: campaign.id
        });
      }

      action.history.push(`/survey/${campaign.id}/edit`);

      yield put({ type: actions.CREATE_CAMPAIGN_SUCCEEDED, campaign });
    } catch (err) {
      yield put({ type: actions.CREATE_CAMPAIGN_FAILED, error: err.message });
    }
  };

const DeleteCampaign = api =>
  function* deleteCampaign(action) {
    try {
      yield call(api.deleteCampaign, action.id);
      yield put({ type: actions.DELETE_CAMPAIGN_SUCCEEDED });
      yield put({ type: actions.GET_CAMPAIGNS_REQUESTED });
    } catch (err) {
      yield put({ type: actions.DELETE_CAMPAIGN_FAILED, error: err.message });
    }
  };

const PublishCampaign = api =>
  function* publishCampaign(action) {
    try {
      yield call(api.publishCampaign, action.id);
      yield put({ type: actions.GET_CAMPAIGNS_REQUESTED });
    } catch (err) {
      yield put({ type: actions.PUBLISH_CAMPAIGN_FAILED, error: err.message });
    }
  };

const UnpublishCampaign = api =>
  function* unpublishCampaign(action) {
    try {
      yield call(api.unpublishCampaign, action.id);
      yield put({ type: actions.GET_CAMPAIGNS_REQUESTED });
    } catch (err) {
      yield put({
        type: actions.UNPUBLISH_CAMPAIGN_FAILED,
        error: err.message
      });
    }
  };

const PayCampaign = api =>
  function* payCampaign(action) {
    try {
      yield call(api.payCampaign, action.id);
      yield put({ type: actions.GET_CAMPAIGNS_REQUESTED });
    } catch (err) {
      yield put({ type: actions.PAY_CAMPAIGN_FAILED, error: err.message });
    }
  };

const RequestApprovalCampaign = api =>
  function* requestApprovalCampaign(action) {
    try {
      const campaign = yield call(api.getCampaign, action.id);
      let campaignCompleted = true;
      if (campaign.status === 'incomplete') {
        yield put({ type: actions.REQUEST_APPROVAL_FAILED, error: null });
        campaignCompleted = false;
      }
      if (campaignCompleted) {
        if (checkIfAllCookiesAllowed()) {
          mixpanel.actions.trackEvent(mixpanel.events.CLIENT_REQUEST_APPROVAL, {
            [mixpanel.eventProperties.SURVEY_ID]: campaign.id
          });
        }
        yield call(api.payCampaign, action.id);
        yield put({ type: actions.GET_CAMPAIGNS_REQUESTED });
        action.onSuccess(action.history);
      } else {
        action.onFailure();
      }
    } catch (err) {
      yield put({ type: actions.REQUEST_APPROVAL_FAILED, error: err.message });
    }
  };

const WithdrawApprovalRequest = api =>
  function* withdrawApprovalRequest(action) {
    try {
      const campaign = yield call(api.getCampaign, action.id);
      campaign.approvalRequestSent = false;
      yield call(api.updateCampaign, campaign);
      yield put({ type: actions.GET_CAMPAIGNS_REQUESTED });
    } catch (err) {
      yield put({
        type: actions.WITHDRAW_APPROVAL_REQUEST_FAILED,
        error: err.message
      });
    }
  };

const DuplicateCampaign = () =>
  function* duplicateCampaign() {
    try {
      // const selectedCampaign = yield select(state => state.campaigns.portal.selectedCampaign);
      // const campaignAttributes = { description: selectedCampaign.description };
      // const createdCampaign = yield call(api.createCampaign);
      yield put({ type: actions.DUPLICATE_CAMPAIGN_SUCCEEDED });
    } catch (err) {
      yield put({
        type: actions.DUPLICATE_CAMPAIGN_FAILED,
        error: err.message
      });
    }
  };

const LoadResultsPage = api =>
  function* loadResultsPage(action) {
    try {
      const resultsData = yield call(
        api.getResults,
        action.id,
        action.viewToken
      );
      const results = resultsData.json.data;

      const dropOutResultsData = yield call(
        api.getDropOutResults,
        action.id,
        action.viewToken
      );
      const dropOutResults = dropOutResultsData.json.data;

      yield put({
        type: actions.LOAD_RESULTS_PAGE_SUCCEEDED,
        results,
        dropOutResults
      });
    } catch (err) {
      yield put({ type: actions.LOAD_RESULTS_PAGE_FAILED, error: err.message });
    }
  };

const GetCampaignOverallStats = api =>
  function* getCampaignOverallStats(action) {
    try {
      const stats = yield call(api.getCampaignOverallStats, action.campaignId);
      yield put({
        type: actions.GET_CAMPAIGN_OVERALL_STATS_SUCCEEDED,
        stats: stats.json
      });
    } catch (err) {
      yield put({
        type: actions.GET_CAMPAIGN_OVERALL_STATS_FAILED,
        error: err.message
      });
    }
  };

const GenerateResultsFile = api =>
  function* generateResultsFile(action) {
    try {
      const exportBlob = yield call(
        api.generateResultsFile,
        action.id,
        action.filetype,
        action.questionIndices,
        action.viewToken,
        action.exportRemovedResponses
      );

      FileSaver.saveAs(exportBlob, `export_${action.id}.${action.filetype}`, {
        autoBom: true
      });
      yield put({ type: actions.GENERATE_FILE_SUCCEEDED });
    } catch (err) {
      yield put({ type: actions.GENERATE_FILE_FAILED, error: err.message });
    }
  };

const MergeDatasets = api =>
  function* mergeDatasets(action) {
    const idsString = action.ids.join(',');
    try {
      const exportBlob = yield call(api.mergeDatasets, idsString);
      FileSaver.saveAs(exportBlob, `export_merge_datasets.${action.filetype}`);
      yield put({ type: actions.MERGE_DATASETS_SUCCEEDED });
    } catch (err) {
      yield put({ type: actions.MERGE_DATASETS_FAILED, error: err.message });
    }
  };

const GoToPortalPage = api =>
  function* goToPortalPage(action) {
    try {
      const campaign = yield select(
        state => state.campaigns.portal.campaignBuilder.campaign
      );
      const activeBlock = yield select(
        state => state.campaigns.portal.campaignBuilder.activeBlock
      );

      if (activeBlock && activeBlock) {
        delete activeBlock.reference;
      }

      if (activeBlock && activeBlock.id === 'welcome') {
        yield call(api.updateCampaign, campaign);
      }
      if (activeBlock && activeBlock.id !== 'welcome') {
        yield call(api.updateBlock, campaign.id, activeBlock.id, activeBlock);
      }
      yield put({ type: actions.GET_CAMPAIGNS_REQUESTED });
      yield put({ type: actions.GO_TO_PORTAL_PAGE_SUCCEEDED });
      action.onSuccess(action.history);
    } catch (err) {
      yield put({ type: actions.GO_TO_PORTAL_PAGE_FAILED, error: err.message });
    }
  };

const SearchCampaigns = () =>
  function* searchCampaigns() {
    yield put({ type: actions.GET_CAMPAIGNS_REQUESTED });
  };

const AssignCategoresToOpenAnswer = api =>
  function* assignCategoresToOpenAnswer(action) {
    try {
      yield call(
        api.assignCategoresToOpenAnswer,
        action.campaignId,
        action.categorization,
        action.viewToken
      );

      const survey = yield select(
        state => state.campaigns.portal.campaignResults.survey
      );
      const resultsData = yield call(
        api.getResults,
        survey.id,
        action.viewToken
      );
      const results = resultsData.json.data;
      yield put({
        type: actions.SET_RESULTS_ONLY,
        results
      });
    } catch (err) {
      yield put({
        type: actions.ASSIGN_CATEGORIES_TO_OPEN_ANSWER_FAILED,
        error: err.message
      });
    }
  };

export default api =>
  function* campaignSaga() {
    yield all([
      takeLatest(actions.GET_CAMPAIGNS_REQUESTED, GetCampaigns(api)),
      takeLatest(actions.SEARCH_CAMPAIGNS, SearchCampaigns(api)),
      takeLatest(actions.CREATE_CAMPAIGN_REQUESTED, CreateCampaign(api)),
      takeLatest(actions.DELETE_CAMPAIGN_REQUESTED, DeleteCampaign(api)),
      takeLatest(actions.PUBLISH_CAMPAIGN_REQUESTED, PublishCampaign(api)),
      takeLatest(actions.UNPUBLISH_CAMPAIGN_REQUESTED, UnpublishCampaign(api)),
      takeLatest(actions.PAY_CAMPAIGN_REQUESTED, PayCampaign(api)),
      takeLatest(
        actions.REQUEST_APPROVAL_REQUESTED,
        RequestApprovalCampaign(api)
      ),
      takeLatest(
        actions.WITHDRAW_APPROVAL_REQUEST_REQUESTED,
        WithdrawApprovalRequest(api)
      ),
      takeLatest(actions.DUPLICATE_CAMPAIGN_REQUESTED, DuplicateCampaign(api)),
      takeLatest(actions.LOAD_RESULTS_PAGE_REQUESTED, LoadResultsPage(api)),
      takeLatest(actions.GENERATE_FILE_REQUESTED, GenerateResultsFile(api)),
      takeLatest(actions.MERGE_DATASETS_REQUESTED, MergeDatasets(api)),
      takeLatest(actions.GO_TO_PORTAL_PAGE_REQUESTED, GoToPortalPage(api)),
      takeLatest(
        actions.GET_CAMPAIGN_OVERALL_STATS_REQUESTED,
        GetCampaignOverallStats(api)
      ),
      takeLatest(
        actions.ASSIGN_CATEGORIES_TO_OPEN_ANSWER_REQUESTED,
        AssignCategoresToOpenAnswer(api)
      )
    ]);
  };
