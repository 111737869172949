import React, { useEffect } from 'react';
import Loader from './Loader/Loader';

export default props => {
  let baseUrl;
  if (
    process.env.NODE_ENV === 'development' ||
    process.env.NODE_ENV === 'test'
  ) {
    baseUrl = 'http://localhost:8080';
  } else if (process.env.REACT_APP_ENV === 'qa') {
    baseUrl = 'https://test.buffl.be';
  } else {
    baseUrl = 'https://buffl-prod-backend.azurewebsites.net';
  }

  const getAcquisitionRedirectionLink = async url =>
    fetch(url, {
      headers: {
        Accept: 'application/json, text/plain, */*',
        'Content-Type': 'application/json',
        'X-app-version': 1000
      },
      method: 'GET'
    });

  const redirectToCampaign = async () => {
    if (props && props.match && props.match.params) {
      const acquisition = await getAcquisitionRedirectionLink(
        `${baseUrl}/acquisition-link/${props.match.params.id}`
      );

      const acquisitionData = await acquisition.json();

      if (acquisitionData && acquisitionData.url) {
        window.location.replace(acquisitionData.url);
      }
    }
  };

  useEffect(() => {
    redirectToCampaign();
  }, []);

  return <Loader />;
};
