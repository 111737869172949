import React, { useState } from 'react';
import mixpanel from 'mixpanel-browser';

import styles from './CookiesPopup.module.css';

export default () => {
  const [acceptedCookies, setAcceptedCookies] = useState(
    localStorage.getItem('@buffl:web-pp-v2-accepted')
  );

  if (acceptedCookies) {
    return null;
  }

  const onAcceptAllCookiesClick = () => {
    localStorage.setItem('@buffl:web-pp-v2-accepted', '1');
    setAcceptedCookies('1');

    // Enable Mixpanel
    mixpanel.init('5b47ecf562d5e8b74404f212cc11857a');
  };

  const onRejectAllNonEssentialClick = () => {
    localStorage.setItem('@buffl:web-pp-v2-accepted', '2');
    setAcceptedCookies('2');
  };

  return (
    <>
      <div className={styles.topMarginContainer} />
      <div className={styles.container}>
        <div className={styles.contentContainer}>
          <div className={styles.description}>
            We use cookies to ensure basic functionality of the site and to
            enhance your experience. Necessary cookies are essential for the
            website{"'"}s operation, while analytical cookies help us improve
            our services. You can accept all cookies or reject non-essential
            cookies. Learn more in our{' '}
            <a
              href="https://www.buffl.be/legal/cookie-policy"
              target="_blank"
              rel="noopener noreferrer"
            >
              cookie policy
            </a>
            .
          </div>
          <div className={styles.acceptButtonsContainer}>
            <div
              className={styles.acceptButton}
              role="presentation"
              onClick={onAcceptAllCookiesClick}
            >
              Accept all cookies
            </div>
          </div>
          <div className={styles.rejectButtonsContainer}>
            <div
              className={styles.rejectButton}
              role="presentation"
              onClick={onRejectAllNonEssentialClick}
            >
              Reject all non-essential cookies
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
