import React from 'react';
import { BrowserRouter, Switch, Route } from 'react-router-dom';
import AcquisitionRedirectionPage from './components/AcquisitionRedirectionPage';

const Router = () => (
  <div style={style.fullHeight}>
    <BrowserRouter basename="/acquisition">
      <div style={style.fullHeight}>
        <Switch>
          <Route exact path="/:id" component={AcquisitionRedirectionPage} />
        </Switch>
      </div>
    </BrowserRouter>
  </div>
);

const style = {
  fullHeight: {
    height: '100vh'
  }
};

export default Router;
