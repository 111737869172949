import React from 'react';
import { useMutation } from '@apollo/react-hooks';

import {
  CREATE_SCOPING_NEXT_STEP,
  UPDATE_SCOPING_NEXT_STEP
} from '../../../../../../graphql/Scoping';

import InsightsTextarea from '../../../Insights/components/InsightsPopup/components/InsightsTextarea/InsightsTextarea';
import Loader from '../../../../Loader/Loader';

import tickIcon from '../../../../../../assets/img/tick.svg';
import closeIcon from '../../../../../../assets/img/delete.svg';

import styles from './NextStepsPopup.module.css';

const NextStepsPopup = props => {
  const {
    internalData,
    setInternalData,
    surveyId,
    nextStepPopup,
    setNextStepPopup,
    getRichTextString,
    surveyRefetch
  } = props;

  const [
    createScopingNextStep,
    { loading: isCreateScopingNextStepLoading }
  ] = useMutation(CREATE_SCOPING_NEXT_STEP);

  const [
    updateScopingNextStep,
    { loading: isUpdatecopingNextStepLoading }
  ] = useMutation(UPDATE_SCOPING_NEXT_STEP);

  const isLoading =
    isCreateScopingNextStepLoading || isUpdatecopingNextStepLoading;

  const onConfirmClick = async () => {
    const data = { ...internalData };
    data.survey = surveyId;

    if (nextStepPopup && nextStepPopup.nextStepId) {
      data.id = nextStepPopup.nextStepId;

      await updateScopingNextStep({
        variables: data
      });
    } else {
      await createScopingNextStep({
        variables: data
      });
    }

    setNextStepPopup(false);
    setInternalData({});
    surveyRefetch();
  };

  const isValidToComplete = () => {
    const descriptionRichText =
      internalData &&
      internalData.description &&
      getRichTextString(internalData.description);

    return descriptionRichText && descriptionRichText !== '';
  };

  return (
    <div
      className={styles.backgroundContainer}
      role="presentation"
      onClick={() => {
        setNextStepPopup(false);
        setInternalData({});
      }}
    >
      <div
        className={styles.popupContainer}
        role="presentation"
        onClick={e => e.stopPropagation()}
      >
        <div className={styles.popupContent}>
          <div className={styles.title}>
            {nextStepPopup.nextStepId ? 'Edit next step' : 'Add next step'}
          </div>
          <div className={styles.fieldsContainer}>
            <InsightsTextarea
              internalData={internalData}
              setInternalData={setInternalData}
            />
          </div>
        </div>
        <div className={styles.footer}>
          <div className={styles.details} />
          <div className={styles.actions}>
            {!isLoading ? (
              <img
                className={
                  isValidToComplete()
                    ? styles.confirm
                    : `${styles.confirm} ${styles.disabledConfirm}`
                }
                src={tickIcon}
                alt="Confirm icon"
                onClick={() => {
                  if (isValidToComplete()) {
                    onConfirmClick();
                  }
                }}
                role="presentation"
              />
            ) : null}
            {isLoading ? (
              <div className={styles.loaderContainer}>
                <Loader size="small" />
              </div>
            ) : null}
            <img
              className={styles.close}
              src={closeIcon}
              alt="Close icon"
              onClick={() => {
                setNextStepPopup(false);
                setInternalData({});
              }}
              role="presentation"
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default NextStepsPopup;
