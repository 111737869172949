export default {
  CONFIRM: 'Bevestig',
  TERMS: 'Voorwaarden',
  SURVEY: 'Survey',
  YOUR_INFO: 'Jouw info',
  THANKS: 'Bedankt',
  BY_CLICK_AGREE:
    'Door op akkoord en doorgaan te klikken, ga ik akkoord met de voorwaarden van: de algemene voorwaarden en het privacybeleid',
  AGREE_AND_CONTINUE: 'Akkoord en doorgaan',
  BACK: 'Terug',
  NEXT: 'Volgende',
  I_AM_AWARE_OF_THE: 'Ik ben op de hoogte van de',
  READ_AND_UNDERSTAND: '',
  THE_TERMS_AND_CONDITIONS: 'De algemene voorwaarden en het privacybeleid',
  TERMS_AND_CONDITIONS: 'algemene voorwaarden & privacy policy',
  AND_AGREE_BY_CHECKING_THIS_BOX:
    'en bevestig deze met het aanvinken van deze box.',
  AND_CONFIRM_I_AGREE: 'en bevestig dat ik akkoord ga.',
  I_AGREE: 'Ik ga akkoord',
  GOVERNED_BY_BUFFL_POLICY:
    'Op deze enquête is het privacybeleid van BUFFL van toepassing.\nLees ons',
  PRIVACY_POLICY: 'privacybeleid',
  BEFORE_PROCEEDING: 'voordat je verder gaat.',
  AGREE_TO_PROCEED: 'Ga je akkoord om verder te gaan?',
  YES_I_AGREE: 'Ja, ik ga akkoord',
  NO_I_DO_NOT_AGREE: 'Nee, ik ga niet akkoord',
  PLEASE_SELECT_VALUE: 'Selecteer',
  PLEASE_ENTER: 'Vul in.',
  WE_ARE_ALMOST_THERE: 'We willen je eerst nog een beetje beter leren kennen',
  FEMALE: 'Vrouw',
  MALE: 'Man',
  OTHER: 'Andere',
  GENDER: 'Geslacht',
  AGE: 'Leeftijd',
  POSTAL_CODE: 'Postcode',
  CREATE_USER_PROFILE: 'Maak een gebruikersprofiel aan',
  PASSWORD: 'Wachtwoord',
  REPEAT_PASSWORD: 'Herhaal wachtwoord',
  PLEASE_REPEAT_PASSWORD: 'Vul je wachtwoord nogmaals in, a.u.b',
  WE_NEED_YOUR_EMAIL: 'We hebben jouw e-mailadres nodig.',
  SO_WE_CAN_SEND_YOU_EMAIL:
    '.. Zodat we je een verificatiemail kunnen sturen om je deelname te valideren',
  EMAIL: 'E-mail',
  SO_YOU_CAN_BE_CONTACTED_IF_WIN:
    '.. Zodat je gecontacteerd kan worden wanneer je een prijs wint',
  YES_I_WANT_TO_BE_CONTACTED_IF_WIN:
    'Ja, ik wil gecontacteerd worden als ik win',
  SO_YOU_CAN_BE_CONTACTED_FOR_THE_NEXT_PARTS:
    '.. Zodat je gecontacteerd kan worden voor de volgende delen van de enquête',
  YES_I_WANT_TO_BE_CONTACTED_FOR_NEXT_SURVEYS:
    'Ja, ik wil gecontacteerd worden voor de volgende enquêtes',
  SO_YOU_CAN_BE_ADDED_TO_MAILING_LIST_OF_CUSTOMER:
    '.. Zodat je aan de mailinglijst kan toegevoegd worden',
  YES_I_WANT_TO_BE_ADDED_TO_MAILING_LIST_OF_CUSTOMER:
    'Ik wil graag aan de mailinglijst worden toegevoegd',
  SO_YOU_CAN_HAVE_OWN_BUFFL_LOGIN:
    '..Zodat je jouw eigen BUFFL-account kan hebben.',
  WHAT_IS_BUFFL_ABOUT: 'Wat is BUFFL?',
  AT_BUFFL_YOU_CAN_HELP_DETERMINE_PRODUCTS:
    'Bij BUFFL kan je helpen bepalen hoe de producten, diensten en merken van morgen eruit moeten zien. Op de mobiele app van BUFFL kan je deelnemen aan meer campagnes en word je beloond met digitale cadeaubonnen!',
  THE_SURVEY_YOU_ARE_LOOKING_IS_UNFORTUNATELY_NO_AVAILABLE: 'Dank je wel!',
  THE_SURVEY_YOU_ARE_LOOKING_IS_NO_AVAILABLE:
    'De survey die je momenteel zoekt, is niet langer beschikbaar.',

  CHECKOUT_AWESOME_BUFFL_APP:
    'Check onze geweldige BUFFL-app voor meer surveys en word beloond met fantastische prijzen.',
  START: 'Start',
  ALL_FIELDS_ARE_REQUIRED: 'Alle velden zijn vereist',
  PASSWORDS_ARE_NOT_SAME: 'De wachtwoorden stemmen niet overeen',
  EMAIL_IS_REQUIRED: 'E-mail is vereist',
  WE_HAVE_SENT_YOU_EMAIL_WITH_CONFIRMATION_LINK:
    'We hebben jou een e-mail gestuurd met een link die jouw e-mailadres bevestigt. Het is mogelijk dat ons mailtje in je spam is beland.',
  RESULT_ACCEPTED: 'Het resultaat werd goedgekeurd.',
  YOUR_RESULTS_HAS_BEEN_CONFIRMED: 'Jouw resultaat werd succesvol bevestigd.',
  SURVEY_FAILED: 'Hartelijk dank voor jouw deelname!',
  UNFORTUNATELY_YOU_ARE_NOT_ELIGIBLE: 'Dank je wel!',
  UNFORTUNATELY: 'Helaas',
  UNFORTUNATELY_ON_FAILED_PAGE: 'Helaas',
  UNFORTUNATELY_ON_NOT_AVAILABLE_PAGE: 'Helaas',
  YOU_ARE_NOT_ELIGIBLE:
    'Je profiel komt niet in aanmerking voor dit onderzoek.',
  DO_NOT_WORRY_DOZENS_OF_RESEARCHES_FAILED:
    'Dank je wel om je mening te willen geven. We hebben al voldoende respondenten met een gelijkaardig profiel als het jouwe. Geen nood, binnenkort komen er weer nieuwe surveys online. Wil je als eerste op de hoogte zijn? Download dan nu de BUFFL-app!',
  DO_NOT_WORRY_DOZENS_OF_RESEARCHES_NOT_AVAILABLE:
    'Dank je wel om je mening te willen geven. We hebben voor deze survey al voldoende respondenten. Geen nood, binnenkort komen er weer nieuwe surveys online. Wil je als eerste op de hoogte zijn? Download dan nu de BUFFL-app!',
  RESULT_CONFIRMED: 'Jouw resultaat werd bevestigd.',
  YOUR_RESULT_WAS_ALREADY_REGISTERED_SUCESSFULY:
    'Jouw antwoord werd al succesvol geregistreerd!',
  IF_YOU_LIKE_PARTICIPATING_IN_MORE_SURVEYS:
    'Als je graag aan meer surveys zou deelnemen en fantastische prijzen wil winnen, installeer dan onze BUFFL-app!',
  CONFIRM_EMAIL: 'Bevestig e-mail.',
  MINIMAL_CHARACTERS: 'Minimaal aantal tekens',
  MAXIMAL_CHARACTERS: 'Maximaal aantal tekens',
  START_A_NEW_SURVEY: 'Start een nieuwe survey',
  READ_FULL_TERMS_AND_CONDITIONS: 'Lees hier de volledige algemene voorwaarden',
  READ_FULL_PRIVACY_POLICY: 'Lees hier ons privacybeleid',

  WELCOME: 'Welkom',
  WELCOME_STEP: 'Welkom',
  USER_PROFILE: 'Gebruikersprofiel',
  CONFIRMATION: 'Bevestiging',
  WE_MAKE_YOU_PLEASANT_HERE:
    'We willen het jou doorheen deze survey zo aangenaam mogelijk maken. Selecteer daarom hieronder in welke taal je wenst verder te gaan.',
  YOU_ARE_NOT_JANE: 'Aangename kennismaking!',
  SOCIO_DEMO: `Voor we de survey kunnen starten, hebben we nog enkele gegevens van je nodig. Dit helpt ons om onze respondenten beter te begrijpen.\n\nWees gerust - BUFFL behandelt jouw gegevens met zorg en de antwoorden die je ingeeft worden anoniem gemaakt.`,
  WE_NEED_FEW_MORE_DETAILS_FROM_YOU:
    'Voor we de survey kunnen starten, hebben we nog enkele gegevens van je nodig. Dit helpt ons om onze respondenten beter te begrijpen.',
  REST_ASSURED_RESPONSES_ARE_ANONYMIZED:
    'Wees gerust - BUFFL behandelt jouw gegevens met zorg en de antwoorden die je ingeeft worden anoniem gemaakt.',
  YOU_ARE: 'Je bent...',
  YOU_ARE_A: 'Je bent een...',
  YOUR_POSTCODE_IS: 'Je postcode is...',
  YEARS_OLD: '...jaar oud.',
  HOW_OLD_ARE_YOU: 'Hoe oud ben je?',
  MALE_SHORT: 'M',
  FEMALE_SHORT: 'V',
  ENTER_POSTCODE: 'Vul je postcode in',
  INTRODUCTION: `Ben je er klaar voor?\n\nTijdens deze survey schotelen we je enkele vragen voor; we zijn daarbij benieuwd naar jouw persoonlijke reactie. Er zijn geen foute antwoorden mogelijk!\n\nDeze survey neemt ongeveer {time} min. van jouw tijd in beslag.\n\nWij wensen jou alvast veel plezier.\n\nLet’s go!`,
  READY_TO_LAUNCH: 'Ben je er klaar voor?',
  DURING_SURVEY_WILL_PRESENT_SURVEY:
    'Tijdens deze survey schotelen we je enkele vragen voor; we zijn daarbij benieuwd naar jouw persoonlijke reactie. Er zijn geen foute antwoorden mogelijk!',
  THIS_SURVEY_TAKES: `Deze survey neemt ongeveer {time} min. van jouw tijd in beslag.`,
  WE_HOPE_YOU_WILL_ENJOY: 'Wij wensen jou alvast veel plezier.',
  LETS_GO: 'Let’s go!',
  JUST_THIS: 'Hartelijk dank voor jouw deelname.',
  WE_CAN_REACH_YOU:
    'Zou je je email kunnen bevestigen? We hebben dit nodig om je antwoorden te valideren.',
  WOULD_YOU_LIKE_TO_RECEIVE_NEW_SURVEYS:
    'Houd mij op de hoogte van toekomstige surveys',
  YOU_WANT_TO_BE_CONTACTED: 'Verwittig mij indien ik een prijs heb gewonnen',
  A_DONKEY_DOESNT_BUMP:
    'Een ezel stoot zich geen twee keer aan dezelfde steen. Maak snel een gebruikersprofiel aan en voorkom tijdsverspilling wanneer je deelneemt aan volgende surveys of wedstrijden!',
  CREATE_A_BUFFL_USER_PROFILE: 'Maak een BUFFL gebruikersprofiel aan',
  BUFFL_HAS_DOZENS_OF_RESEARCHES:
    'BUFFL heeft tientallen onderzoeken per week over allerlei topics waar je aan mee kunt doen. Zo is er voor ieder genoeg wat aansluit bij zijn interesses.',
  COMPLETED: `Zou jij graag meer te weten komen over de nieuwste innovatieve producten en daar je mening over delen, tokens verdienen en kans maken op fantastische prijzen?\n\nInstalleer dan de BUFFL-app, verkrijgbaar in de App Store en Google Play Store.`,
  WOULD_YOU_BE_INTERESTED_GIVING_AN_OPINION:
    'Zou jij graag meer te weten komen over de nieuwste innovatieve producten en daar je mening over delen, tokens verdienen en kans maken op fantastische prijzen?',
  YOUR_ANSWERS_WILL_BE_PROCESSED_SECURELY:
    'Je antwoorden worden veilig en anoniem verwerkt.',
  INSTALL_THE_BUFFL_APP:
    'Installeer dan de BUFFL-app, verkrijgbaar in de App Store en Google Play Store.',
  WOULD_YOU_STAY_UP_OF_LATEST_NEWS_VIA_MAIL:
    'Blijf je liever op de hoogte van de laatste nieuwe surveys via email of wil je enkel je gegevens achterlaten voor een prijs? Laat dan hier je gegevens achter!',
  CHOOSE_PASSWORD: 'Kies je wachtwoord',
  ENTER_YOUR_PASSWORD: 'Vul je wachtwoord in',
  CONFIRM_PASSWORD: 'Bevestig je wachtwoord',
  OPEN_MAILBOX_CONFIRM_ANSWER:
    'Open jouw mailbox en bevestig jouw antwoorden en e-mailadres.',
  CHECK_SPAM_FOLDER:
    'Check zeker ook even jouw spamfolder for the sneaky ones.',
  THANK_YOU: 'Bedankt voor je deelname.',
  MORE_IMPACT: 'Graag meer impact?',
  WE_GOT_YOUR_ANSWERS:
    'We kregen jouw antwoorden vlotjes binnen. Bedankt alvast hiervoor.',
  WE_ARE_BUFFL: 'Wij zijn BUFFL.',
  THROUGH_YOUR_PARTICIPATION:
    'Door jouw deelname aan dit soort surveys, bepaal jij mee hoe producten, diensten en merken er moeten uitzien. Wil je meer deelnemen en telkens kans maken op leuke geschenken? Download dan zeker de BUFFL app en laat iets van jou horen.',
  YOU_HAVE_ALREADY_PARTICIPATED: 'Je hebt reeds deelgenomen aan deze survey',
  SHARE_YOUR_OPINION: `Deel je mening in de app door korte & leuke surveys`,
  CONTRIBUTE_TO_INNOVATIONS_OF_TOMORROW:
    'Draag bij aan de innovaties van morgen',
  BE_REWARDED: 'Word beloond met geschenkbonnen van jouw favoriete merken',
  REVIEW_ONE:
    'Ik vind deze app geweldig omdat het interessante vragen zijn en je er leuke spullen mee kunt winnen!',
  REVIEW_TWO:
    'Toffe app waarbij je je mening geeft in ruil voor punten, die je na een tijdje kan omwisselen in cadeautjes. - Korte vragenlijstjes, prettig gestoord en doorspekt met humor met focus op weinig-tijd vragen. Ideaal om tussendoor te gebruiken. Ik spaarde al een cadeaubon van 10 euro!',
  REVIEW_THREE:
    'Andere enquêtes zijn saai en duren lang. BUFFL laat zien dat het anders kan. Korte, leuke surveys waarbij ze jouw mening vragen. Een concept met tokens sparen waardoor je leuke extraatjes krijgt.',
  JOIN_THE_HERD: 'Join the herd',
  WHAT_DOES_BUFFL_OFFER:
    'Wat heeft de BUFFL-app te bieden? Verdien tokens of steun goede doelen. Leer passief bij via leuke weetjes en de nieuwste innovaties. Heb een impact op de innovaties van morgen.',
  DATE_OF_BIRTH: 'Geboortedatum',
  CHOOSE_A_GENDER: 'Kies een geslacht',
  CREATE_ACCOUNT: 'Account maken',
  CONGRATULATIONS: 'Proficiat',
  PART_OF_THE_HERD: 'Je maakt nu officieel deel uit van onze kudde.',
  DOWNLOAD_AND_START_EARNING_TOKENS:
    'Download de app en begin met tokens te verdienen',
  DOWNLOAD_THE_APP_NOW: 'Download nu de BUFFL-app!',
  PLEASE_FILL_FIELD: 'Gelieve dit veld in te vullen',
  PLEASE_ENTER_VALID_EMAIL: 'Gelieve een geldig e-mailadres in te vullen',
  PLEASE_ENTER_DATE_OF_BIRTH_RIGHT_FORMAT:
    'Gelieve in het juiste format in te voeren',
  PLEASE_ENTER_VALID_DATE_OF_BIRTH:
    'Gelieve een geldige geboortedatum in te vullen',
  PLEASE_SELECT_GENDER: 'Gelieve een geslacht te kiezen',
  PASSWORDS_NOT_SAME: 'Wachtwoorden zijn niet hetzelfde',
  EMAIL_ALREADY_USED: 'Dit e-mailadres is al in gebruik',
  START_SURVEY: 'Start survey',
  GIVE_YOUR_OPINION_THERE_ARE_NO_BAD_ANSWERS:
    'Tijdens deze survey is het belangrijk dat je je mening geeft. Verkeerd antwoorden kan niet. Hoe eerlijker, hoe liever!',
  LOADING_IMAGE_FAILED: 'De afbeelding kan niet worden geladen.',
  AMOUNT_OF_ANWSERS: 'Aantal antwoorden',
  PLEASE_ENTER_VALID_POSTAL_CODE: 'Gelieve een geldige postcode in te vullen.',
  OPEN_QUESTION_REMAINING_CHARACTERS: 'aantal overgebleven tekens',
  OPEN_QUESTION_MIN_CHARACTERS: 'min.',
  WOULD_YOU_LIKE_TO_HAVE_MORE_IMPACT_ON_DECISIONS_BY_PARTICIPATING_IN_SURVEYS:
    'Wil je graag meer impact hebben op beslissingen door deel te nemen aan korte surveys zoals deze?\n\n+ We geven een waardebon weg voor een 3-daags familieweekend ter waarde van € 199,90',
  WOULD_YOU_LIKE_TO_HAVE_MORE_IMPACT:
    'Wil je graag meer impact hebben op beslissingen door deel te nemen aan korte surveys zoals deze?',
  I_AM_NOT_INTERESTED: 'Ik ben niet geïnteresseerd.',
  WELL_KEEP_IN_TOUCH: 'We houden je verder op de hoogte.',
  YOU_HAVE_ALREADY_EARNED_TOKENS_BY_COMPLETING_SURVEYS:
    'Je hebt al €{euro} verdiend met het invullen van {surveys} surveys. Klaar om je beloning te claimen?\nDownload de app, registreer met {email} en wij storten de tokens op je account.',
  WE_HAVE_NOTICED_EMAIL_IS_LINKED_TO_MOBILE_ACCOUNT:
    'We hebben gemerkt dat {email} gekoppeld is aan een account op onze mobile app.\nJe ontvangt {tokens} tokens voor het invullen van deze survey. ',
  YOU_HAVE_ALREADY_CLAIMED_THE_REWARD_FOR_THIS_SURVEY:
    'Je hebt reeds een beloning ontvangen.',
  SEC: 'sec',
  PROFILING_QUESTION_CONSENT_TITLE: 'We willen je surveys korter maken',
  PROFILING_QUESTION_CONSENT_DESCRIPTION:
    'Mogen we je antwoord op enkele vragen opslaan? Dit gebeurt volledig anoniem en zorgt ervoor dat je deze specifieke vragen niet meer te zien krijgt in toekomstige surveys.',
  PROFILING_QUESTION_YES_CONSENT: 'Ja, ik wil graag kortere surveys!',
  PROFILING_QUESTION_NO_CONSENT: 'Nee, liever niet.',
  PROFILING_QUESTION_NO_DO_NOT_ASK: 'Nee, vraag niet opnieuw voor deze vragen.',
  PROFILING_QUESTION_NO_DO_NOT_ASK_EVER:
    'Nee, vraag niet opnieuw voor deze of andere vragen.',
  CONVERSION_THANK_YOU: 'Bedankt voor je deelname.',
  CONVERSION_NO_CONSENT: 'Je antwoorden worden veilig en anoniem verwerkt.',
  SELECT_ANSWERS: 'Kies {selectAtLeast} antwoorden',
  SELECT_AT_LEAST_AND_AT_MOST_ANSWERS:
    'Kies minimaal {selectAtLeast} en maximaal {selectAtMost} antwoorden',
  MATRIX: {
    I_STRONGLY_DISAGREE: 'Helemaal niet akkoord',
    I_DISAGREE: 'Eerder niet akkoord',
    I_AGREE: 'Eerder akkoord',
    I_STRONGLY_AGREE: 'Helemaal akkoord',
    I_NEITHER_AGREE_NOR_DISAGREE: 'Niet mee eens of oneens',
    I_SOMEWHAT_DISAGREE: 'Enigszins mee oneens',
    I_SOMEWHAT_AGREE: 'Enigszins mee eens'
  },
  END_OF_THE_SURVEY_THANK_YOU_SCREEN_PENDING_EUROS_CONVERSION:
    'Je hebt al €{EUROS} verdiend met het invullen van {SURVEYS} survey(s). Klaar om je beloning te claimen? Download the de, registreer met {EMAIL} en wij storten het bedrag op je account.',
  END_OF_THE_SURVEY_THANK_YOU_SCREEN_PENDING_TOKENS_CONVERSION:
    'Je hebt al {TOKENS} tokens verdiend met het invullen van {SURVEYS} survey(s). Klaar om je beloning te claimen? Download de app, registreer met {EMAIL} en wij storten de tokens op je account.',
  END_OF_THE_SURVEY_THANK_YOU_SCREEN_PENDING_IMPACT_CONVERSION:
    'Klaar om je frustraties, ideeën of inzichten rond het thema … in de praktijk om te zetten? Download de app en registreer met {EMAIL}',
  END_OF_THE_SURVEY_THANK_YOU_SCREEN_PENDING_CUSTOM_CONVERSION: '',

  END_OF_THE_SURVEY_THANK_YOU_SCREEN_EXISTING_EUROS_CONVERSION:
    'We hebben gemerkt dat {EMAIL} gekoppeld is aan een account op onze mobile app. Je ontvangt €{EUROS} voor het invullen van deze survey.',
  END_OF_THE_SURVEY_THANK_YOU_SCREEN_EXISTING_TOKENS_CONVERSION:
    'We hebben gemerkt dat {EMAIL} gekoppeld is aan een account op onze mobile app. Je ontvangt {TOKENS} tokens voor het invullen van deze survey.',
  END_OF_THE_SURVEY_THANK_YOU_SCREEN_EXISTING_IMPACT_CONVERSION:
    'Klaar om je frustraties, ideeën of inzichten rond het thema … in de praktijk om te zetten? Download de app en log in met {EMAIL}.',
  END_OF_THE_SURVEY_THANK_YOU_SCREEN_EXISTING_CUSTOM_CONVERSION: '',

  END_OF_THE_SURVEY_THANK_YOU_TO_WEB_CONVERSION:
    'We houden je verder op de hoogte.',

  USER_TOKEN_DO_YOU_WANT_TO_CONTINUE_AS: 'Wil je doorgaan als',
  USER_TOKEN_DO_YOU_WANT_TO_CONTINUE_NO_NEED_DATA:
    'Dan hoef je je leeftijd, geslacht en postcode niet opnieuw in te vullen.',
  USER_TOKEN_DO_YOU_WANT_TO_CONTINUE_YES: 'Ja, dat ben ik!',
  USER_TOKEN_DO_YOU_WANT_TO_CONTINUE_NO: 'Nee, ga door als gast'
};
