import React, { useEffect, useState, useContext } from 'react';

import Popup from '../../../Popup/Popup';
import SchedulePopup from '../SchedulePopup/SchedulePopup';
import SurveyBuilderPageContext from '../../../SurveyBuilderPageContext';

import * as mixpanel from '../../../../../../../mixpanel';

import styles from './Step.module.css';
import './Step.css';
import { checkIfAllCookiesAllowed } from '../../../../../../helpers/privacy';

const months = {
  0: 'January',
  1: 'February',
  2: 'March',
  3: 'April',
  4: 'May',
  5: 'June',
  6: 'July',
  7: 'August',
  8: 'September',
  9: 'October',
  10: 'November',
  11: 'December'
};
const parseDate = val => (val < 10 ? `0${val}` : val);

const buttonTypeChoices = {
  CANCEL: styles.cancelButton,
  SCHEDULE: styles.scheduleButton,
  INVISIBLE: styles.invisibleButton
};

const Button = ({ type = 'SCHEDULE', disabled, label, ...rest }) => (
  <div
    role="presentation"
    className={`${styles.button} ${
      disabled ? styles.disabledButton : styles.enabledButton
    }  ${buttonTypeChoices[type]}`}
    {...rest}
  >
    {label}
  </div>
);

export default ({
  stepId,
  survey,
  author,
  date,
  isStart,
  moment,
  type,
  getSurvey,
  headerText,
  publishScheduled,
  additionalButton
}) => {
  const [showPopup, setShowPopup] = useState(false);
  const [mutationPerformed, setMutationPerformed] = useState(false);

  const { api } = useContext(SurveyBuilderPageContext);

  useEffect(
    () => {
      if (!showPopup && mutationPerformed) {
        getSurvey();
        setMutationPerformed(false);
      }
    },
    [showPopup, mutationPerformed]
  );

  const timezoneOffest = new Date().getTimezoneOffset() / 60;

  const footerText =
    date &&
    `on ${months[date.getMonth()]} ${parseDate(
      date.getDate()
    )}, ${date.getFullYear()} at ${parseDate(date.getHours())}:${parseDate(
      date.getMinutes()
    )} (GMT ${Math.sign(timezoneOffest) <= 0 ? '+' : '-'}${Math.abs(
      timezoneOffest
    )})`;

  const controlMap = {
    PAST: {
      PUBLISH_SURVEY: {
        headerText: 'Survey published',
        footerText,
        footerButton: () => null,
        popupComponent: () => null
      },
      UNPUBLISH_SURVEY: {
        headerText: 'Survey unpublished',
        footerText,
        footerButton: () => null,
        popupComponent: () => null
      },
      REQUEST_APPROVAL: {
        headerText: 'Publish requested',
        footerText,
        footerButton: () => null,
        popupComponent: () => null
      }
    },
    NOW: {
      PUBLISH_SURVEY: {
        headerText: 'Survey published',
        footerText,
        footerButton: () => (
          <Button
            label="Unpublish"
            type="CANCEL"
            onClick={async () => {
              await api('publishSurvey', {
                variables: { survey: survey.id, publish: false }
              });
              getSurvey();

              if (checkIfAllCookiesAllowed()) {
                mixpanel.actions.trackEvent(
                  mixpanel.events.CLIENT_PUBLISH_SURVEY,
                  {
                    [mixpanel.eventProperties.SURVEY_ID]: survey.id
                  }
                );
              }
            }}
          />
        ),
        popupComponent: () => null
      },
      UNPUBLISH_SURVEY: {
        headerText: 'Survey unpublished',
        footerText,
        footerButton: () => (
          <Button
            label="Publish"
            onClick={async () => {
              await api('publishSurvey', {
                variables: {
                  survey: survey.id,
                  publish: true
                }
              });
              getSurvey();
            }}
          />
        ),
        popupComponent: () => null
      },
      REQUEST_APPROVAL: {
        headerText: 'Publish requested',
        footerText,
        footerButton: () => (
          <Button
            label="Cancel"
            type="CANCEL"
            onClick={async () => {
              await api('requestApproval', {
                variables: {
                  survey: survey.id,
                  status: false
                }
              });
              getSurvey();
              setMutationPerformed(true);

              if (checkIfAllCookiesAllowed()) {
                mixpanel.actions.trackEvent(
                  mixpanel.events.CLIENT_REQUEST_APPROVAL,
                  {
                    [mixpanel.eventProperties.SURVEY_ID]: survey.id
                  }
                );
              }
            }}
          />
        ),
        popupComponent: () => null
      },
      INFO: {
        footerText,
        footerButton: () => null,
        popupComponent: () => null
      }
    },
    FUTURE: {
      PUBLISH_SURVEY: {
        headerText: 'Survey scheduled to publish',
        footerText,
        footerButton: () => (
          <Button
            label="Edit"
            onClick={() => {
              setShowPopup(true);
            }}
          />
        ),
        additionalButton: () => (
          <Button
            label="Publish now"
            style={{ marginTop: '10px' }}
            onClick={async () => {
              await api('publishSurvey', {
                variables: {
                  survey: survey.id,
                  publish: true
                }
              });
              getSurvey();

              if (checkIfAllCookiesAllowed()) {
                mixpanel.actions.trackEvent(
                  mixpanel.events.CLIENT_PUBLISH_SURVEY,
                  {
                    [mixpanel.eventProperties.SURVEY_ID]: survey.id
                  }
                );
              }
            }}
          />
        ),
        popupComponent: () => (
          <SchedulePopup
            actionLabel="PUBLISHING"
            initialDate={date}
            onLeftButtonClick={async () => {
              await api('updateSchedule', {
                variables: {
                  task: stepId,
                  status: 'CANCELED'
                }
              });
              setMutationPerformed(true);
              getSurvey();
            }}
            rightButtonLabel="Cancel"
            onRightButtonClick={() => {
              setShowPopup(false);
            }}
            onDirtyRightButtonClick={async scheduleTime => {
              await api('updateSchedule', {
                variables: {
                  task: stepId,
                  time: new Date(scheduleTime)
                }
              });
              setMutationPerformed(true);
              getSurvey();
            }}
          />
        )
      },
      UNPUBLISH_SURVEY: {
        headerText: 'Survey scheduled to unpublish',
        footerText,
        footerButton: () => (
          <Button
            label="Edit"
            onClick={() => {
              setShowPopup(true);
            }}
          />
        ),
        popupComponent: () => (
          <SchedulePopup
            actionLabel="UNPUBLISHING"
            initialDate={date}
            onLeftButtonClick={async () => {
              await api('updateSchedule', {
                variables: {
                  task: stepId,
                  status: 'CANCELED'
                }
              });
              setMutationPerformed(true);
              getSurvey();
            }}
            rightButtonLabel="Cancel"
            onRightButtonClick={() => {
              setShowPopup(false);
            }}
            onDirtyRightButtonClick={async scheduleTime => {
              await api('updateSchedule', {
                variables: {
                  task: stepId,
                  time: new Date(scheduleTime)
                }
              });
              setMutationPerformed(true);
              getSurvey();
            }}
            publishScheduled={publishScheduled}
          />
        )
      }
    },
    POTENTIAL: {
      PUBLISH_SURVEY: {
        headerText: 'Survey publish',
        footerText: 'No time scheduled yet for publishing the survey',
        footerButton: () => (
          <Button
            label="Schedule"
            onClick={() => {
              setShowPopup(true);
            }}
          />
        ),
        additionalButton: () => (
          <Button
            label="Publish now"
            style={{ marginTop: '10px' }}
            onClick={async () => {
              await api('publishSurvey', {
                variables: {
                  survey: survey.id,
                  publish: true
                }
              });
              getSurvey();

              if (checkIfAllCookiesAllowed()) {
                mixpanel.actions.trackEvent(
                  mixpanel.events.CLIENT_PUBLISH_SURVEY,
                  {
                    [mixpanel.eventProperties.SURVEY_ID]: survey.id
                  }
                );
              }
            }}
          />
        ),
        popupComponent: () => (
          <SchedulePopup
            actionLabel="PUBLISHING"
            onRightButtonClick={async scheduleTime => {
              await api('scheduleSurvey', {
                variables: {
                  survey: survey.id,
                  action: 'PUBLISH_SURVEY',
                  time: new Date(scheduleTime)
                }
              });
              setMutationPerformed(true);
              getSurvey();
            }}
          />
        )
      },
      UNPUBLISH_SURVEY: {
        headerText: 'Survey unpublish',
        footerText: 'No time scheduled yet for unpublishing the survey',
        footerWidth: '100%',
        footerButton: () => (
          <Button
            label="Schedule"
            onClick={() => {
              setShowPopup(true);
            }}
          />
        ),
        popupComponent: () => (
          <SchedulePopup
            actionLabel="UNPUBLISHING"
            onRightButtonClick={async scheduleTime => {
              await api('scheduleSurvey', {
                variables: {
                  survey: survey.id,
                  action: 'UNPUBLISH_SURVEY',
                  time: new Date(scheduleTime)
                }
              });
              setMutationPerformed(true);
              getSurvey();
            }}
            publishScheduled={publishScheduled}
          />
        )
      },
      INFO: {
        headerText: 'Publish survey',
        footerText:
          'Your survey will be published as soon as your request is approved',
        footerButton: () => (
          <Button type="INVISIBLE" disabled label="Request pending" />
        ),
        popupComponent: () => null
      }
    }
  };

  return (
    <div className={styles.container}>
      {showPopup && (
        <Popup
          component={controlMap[moment][type].popupComponent()}
          onClose={() => setShowPopup(false)}
          showOverflow
        />
      )}
      <span className={`${styles.headerText} step-header-text-${moment}`}>
        {headerText || controlMap[moment][type].headerText}
      </span>
      <div className={styles.stepContainer}>
        {!isStart && (
          <div
            className={`${styles.stepProgressline} step-progressline-${moment}`}
          />
        )}
        <div className={styles.stepOuterCircle}>
          <div
            className={`${styles.stepInnerCircle} step-inner-circle-${moment}`}
          />
        </div>
      </div>

      <div className={styles.infoContainer}>
        <span className={`${styles.footerText} step-footer-text-${moment}`}>
          {controlMap[moment][type].footerText}
        </span>

        {author && <span className={styles.author}>by {author}</span>}
      </div>
      {controlMap[moment][type].footerButton()}
      {additionalButton && controlMap[moment][type].additionalButton()}
    </div>
  );
};
