import React, { useState, useRef } from 'react';
import ReCAPTCHA from 'react-google-recaptcha';
import { useMutation } from '@apollo/react-hooks';

import { CONTACT_VALIDATION_EXPERT } from '../../../../../../../graphql/Survey';

import * as mixpanel from '../../../../../../../../mixpanel';

import Loader from '../Loader/Loader';

import tickIcon from '../../../../../../../assets/img/tick.svg';
import closeIcon from '../../../../../../../assets/img/delete.svg';
import warningIcon from '../../../../../../../assets/img/exclamation.svg';
import check from '../../../../../../../assets/img/check.svg';

import styles from './ContactInnovationExpertPopup.module.css';
import { checkIfAllCookiesAllowed } from '../../../../../../../helpers/privacy';

const ContactInnovationExpertPopup = props => {
  const {
    onClose,
    surveyId,
    title,
    messagePlaceholder,
    isAllowedToExecuteRequests,
    viewToken
  } = props;

  const [formData, setFormData] = useState({
    firstName: '',
    lastName: '',
    company: '',
    email: '',
    message: ''
  });
  const [errorMessage, setErrorMessage] = useState(null);
  const [successfullySubmitted, setSuccessfullySubmitted] = useState(false);

  const [
    contactValidationExpert,
    { loading: contactValidationExpertLoading }
  ] = useMutation(CONTACT_VALIDATION_EXPERT, {
    onCompleted: response => {
      if (
        response &&
        response.contactInnovationExpert &&
        response.contactInnovationExpert.success
      ) {
        setSuccessfullySubmitted(true);
      } else {
        setErrorMessage('Failed to submit your form');
      }
    }
  });

  const reCaptchaRef = useRef(null);

  const updateFormField = (value, property) => {
    setFormData({
      ...formData,
      [property]: value
    });
  };

  const validateForm = () => {
    let valid = true;
    const regexTest = new RegExp(/^[^\s@]+@[^\s@]+\.[^\s@]+$/);
    if (
      formData.firstName.length &&
      formData.lastName.length &&
      formData.company.length &&
      formData.email.length &&
      formData.message.length
    ) {
      if (!regexTest.test(formData.email)) {
        valid = false;
        setErrorMessage('Please enter a valid email');
      }
    } else {
      setErrorMessage('Empty fields');
      valid = false;
    }
    return valid;
  };

  const submitForm = async reCaptchaValue => {
    if (!contactValidationExpertLoading) {
      await contactValidationExpert({
        variables: {
          survey: surveyId,
          ...formData,
          reCaptcha: reCaptchaValue,
          ...(viewToken ? { viewToken } : {})
        }
      });
    }
  };

  if (successfullySubmitted) {
    return (
      <div className={`${styles.container} ${styles.containerSuccess}`}>
        <div className={styles.content}>
          <div className={styles.title}>
            Your form was successfully submitted.
          </div>
          <div className={styles.successMessage}>
            You will be contacted soon by one of our validation experts.
          </div>
          <img alt="Checkmark" src={check} className={styles.checkmark} />
        </div>
      </div>
    );
  }

  return (
    <div className={styles.container}>
      <div className={styles.content}>
        <div className={styles.title}>{title || 'Ask for help'}</div>
        <div className={styles.form}>
          <input
            type="text"
            className={styles.field}
            value={formData.firstName}
            placeholder="First name"
            onChange={e => updateFormField(e.target.value, 'firstName')}
          />
          <input
            type="text"
            className={styles.field}
            value={formData.lastName}
            placeholder="Last name"
            onChange={e => updateFormField(e.target.value, 'lastName')}
          />
          <input
            type="text"
            className={styles.field}
            value={formData.company}
            placeholder="Company name"
            onChange={e => updateFormField(e.target.value, 'company')}
          />
          <input
            type="email"
            className={styles.field}
            value={formData.email}
            placeholder="E-mail"
            onChange={e => updateFormField(e.target.value, 'email')}
          />
          <textarea
            className={`${styles.field} ${styles.textarea}`}
            value={formData.message}
            placeholder={messagePlaceholder || 'How can we help?'}
            onChange={e => updateFormField(e.target.value, 'message')}
          />
          <ReCAPTCHA
            ref={reCaptchaRef}
            size="invisible"
            sitekey={
              process.env.NODE_ENV === 'development'
                ? '6Lc2yJwdAAAAANzmqQGquqx41k4l1jxlMgJIrS1P'
                : '6LfmGpwdAAAAANUlNgjng8mWe__WFz5e7fDXSxLe'
            }
          />
        </div>
      </div>
      <div className={`${styles.footer} ${errorMessage ? styles.error : ''}`}>
        {errorMessage ? (
          <div className={styles.errorMessage}>
            <img className={styles.warningIcon} src={warningIcon} alt="error" />
            {errorMessage}
          </div>
        ) : (
          <div />
        )}
        <div className={styles.actions}>
          {contactValidationExpertLoading ? (
            <Loader size="small" />
          ) : (
            <img
              src={tickIcon}
              alt="confirm"
              className={`${styles.confirmIcon} ${styles.confirmIconActive}`}
              role="presentation"
              onClick={async () => {
                if (isAllowedToExecuteRequests && validateForm()) {
                  setErrorMessage(null);
                  const reCaptchaValue = await reCaptchaRef.current.executeAsync();
                  if (reCaptchaValue) {
                    submitForm(reCaptchaValue);
                    if (checkIfAllCookiesAllowed()) {
                      mixpanel.actions.trackEvent(
                        mixpanel.events
                          .CLIENT_CONTACT_FOR_HELP_IN_DECISION_FRAMEWORK,
                        {
                          [mixpanel.eventProperties.SURVEY_ID]: surveyId,
                          [mixpanel.eventProperties.FIRSTNAME]:
                            formData.firstName,
                          [mixpanel.eventProperties.LASTNAME]:
                            formData.lastName,
                          [mixpanel.eventProperties.COMPANY]: formData.company,
                          [mixpanel.eventProperties.EMAIL]: formData.email,
                          [mixpanel.eventProperties.MESSAGE]: formData.message
                        }
                      );
                    }
                  }
                }
              }}
            />
          )}
          <img
            src={closeIcon}
            alt="close"
            className={styles.closeIcon}
            role="presentation"
            onClick={() => onClose()}
          />
        </div>
      </div>
    </div>
  );
};

export default ContactInnovationExpertPopup;
