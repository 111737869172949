import gql from 'graphql-tag';

export const CREATE_SCOPING_NEXT_STEP = gql`
  mutation CreateScopingNextStep($survey: ID!, $description: String!) {
    createScopingNextStep(
      input: { survey: $survey, description: $description }
    ) {
      success
      errors {
        type
        message
      }
    }
  }
`;

export const UPDATE_SCOPING_NEXT_STEP = gql`
  mutation UpdateScopingNextStep(
    $id: ID!
    $survey: ID!
    $description: String!
  ) {
    updateScopingNextStep(
      input: { id: $id, survey: $survey, description: $description }
    ) {
      success
      errors {
        type
        message
      }
    }
  }
`;

export const REMOVE_SCOPING_NEXT_STEP = gql`
  mutation RemoveScopingNextStep($id: ID!, $survey: ID!) {
    removeScopingNextStep(input: { id: $id, survey: $survey }) {
      success
      errors {
        type
        message
      }
    }
  }
`;
