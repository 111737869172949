import frameWorkValueNames from '../../helpers/frameWorkValueNames';
import getFeaturesFromQuestion from '../updateDecisionFramework/helpers/getFeaturesFromQuestion';

const ObjectID = require('bson-objectid');

export default (survey, question) => {
  let newSurveyState = null;
  let createRequest = null;

  const valueNames = frameWorkValueNames.find(
    vNames => vNames.type === question.matrix.decisionFrameworkType
  );

  if (
    valueNames &&
    valueNames.xQuestionName &&
    valueNames.yQuestionName &&
    valueNames.valuePath &&
    valueNames.createApi
  ) {
    const { createApi } = valueNames;

    const features = getFeaturesFromQuestion(
      question,
      valueNames.xQuestionName,
      valueNames.yQuestionName
    );

    const newGraph = {
      id: ObjectID().toString(),
      name: 'Decision framework',
      features,
      xLabel: question.matrix.xLabel,
      yLabel: question.matrix.yLabel,
      question: question.id
    };

    const newGraphs = [...(survey[valueNames.valuePath] || []), newGraph];

    newSurveyState = {
      ...survey,
      [valueNames.valuePath]: newGraphs
    };

    createRequest = {
      api: createApi,
      variables: {
        ...newGraph,
        survey: survey.id
      }
    };
  }

  return {
    surveyWithCreatedFramework: newSurveyState,
    createRequest
  };
};
