import React from 'react';
import { BrowserRouter, Switch, Route } from 'react-router-dom';

import ClientsApp from './clients/base/containers/AppContainer';
import UsersApp from './users/App';
import LinkShortenerApp from './link-shortener/App';
import OrganisationsApp from './organisations/App';
import AcquisitionApp from './acquisition/App';

const App = ({ apiUrl, webSocketUrl }) => (
  <BrowserRouter>
    <Switch>
      <Route
        path="/clients"
        render={() => <ClientsApp webSocketUrl={webSocketUrl} />}
      />
      <Route path="/users" render={() => <UsersApp apiUrl={apiUrl} />} />
      <Route path="/survey" component={LinkShortenerApp} />
      <Route path="/acquisition" component={AcquisitionApp} />
      <Route
        path="/organisations"
        render={() => <OrganisationsApp apiUrl={apiUrl} />}
      />
    </Switch>
  </BrowserRouter>
);

export default App;
