import React, { useState, useEffect } from 'react';

import { useLazyQuery, useQuery, useMutation } from '@apollo/react-hooks';

import qs from 'query-string';

import {
  CUSTOM_THEME_COLOR_NAMES,
  CUSTOM_THEME_IMAGE_NAMES
} from '../../../../../clients/surveys/helpers/constants';

import { GET_APP_CONFIGURATION } from '../../../../graphql/Auth';
import {
  GET_RESULT,
  FIND_AND_REWARD_EMAIL_CONVERSION_USER,
  SUBMIT_EMAIL_CONVERSION
} from '../../../../graphql/Campaigns';

import TermsAndConditions from '../CampaignsPage/components/TermsAndConditions/TermsAndConditions';
import AcceptTermsInput from '../../../../form/AcceptTermsInput/AcceptTermsInput';
import Footer from '../Footer/Footer';
import TranslationInputField from '../TranslationInputField/TranslationInputField';

import errorIcon from '../../../../assets/img/error-rebranded.svg';
import GooglePlayStore from '../../../../assets/img/play-store.png';
import AppleStore from '../../../../assets/img/app-store.png';
import QRCode from '../../../../assets/img/qrcode-buffl-app.svg';

import styles from './CampaignEmailConversion.module.css';
import { thankYouScreenConversionMessageOptionKeys } from '../../../../../clients/surveys/components/SurveyBuilderPage/SurveyOverview/components/Conversion/defaultCommunitySettings';

export default props => {
  const {
    campaign,
    result,
    strings,
    match,
    setCampaignComponents,
    updateTranslationsLanguage,
    setCustomThemeBaseColor,
    setCustomThemeImage,
    location: { search },
    customTranslationsLoaded,
    setCustomTranslations,
    translations,
    selectedLanguage,
    userEmailSuggestion,
    savingTranslationError,
    setSavingTranslationError,
    clientId
  } = props;

  const languages = {
    ENGLISH: 'English',
    DUTCH: 'Dutch',
    FRENCH: 'French',
    GERMAN: 'German'
  };

  const userLanguages = {
    EN: 'English',
    NL: 'Dutch',
    FR: 'French',
    DE: 'German'
  };

  const communityTypes = {
    WEB_PANEL: 'WEB_PANEL',
    MOBILE_APP: 'MOBILE_APP'
  };

  const customCopyFields = {
    askForEmailAddressInWebSurvey: {
      name: 'askForEmailAddressInWebSurvey',
      fields: {
        GIVEAWAY:
          'WOULD_YOU_LIKE_TO_HAVE_MORE_IMPACT_ON_DECISIONS_BY_PARTICIPATING_IN_SURVEYS',
        NO_GIVEAWAY: 'WOULD_YOU_LIKE_TO_HAVE_MORE_IMPACT'
      }
    }
  };

  const conversionStatusTranslations = {
    NO_CONSENT: translations.CONVERSION_NO_CONSENT,
    CONSENT_FIRST_TIME:
      translations.END_OF_THE_SURVEY_THANK_YOU_TO_WEB_CONVERSION,
    DUPLICATE_RESULT:
      translations.YOU_HAVE_ALREADY_CLAIMED_THE_REWARD_FOR_THIS_SURVEY
  };

  const communitySettings = campaign && campaign.communitySettings;

  const giveAwayTranslation =
    communitySettings && communitySettings.seeGiveawayMessage
      ? customCopyFields.askForEmailAddressInWebSurvey.fields.GIVEAWAY
      : customCopyFields.askForEmailAddressInWebSurvey.fields.NO_GIVEAWAY;

  const isTesting = () => search === '?test=true';

  const customThemeBaseColor =
    campaign &&
    campaign.customBranding &&
    campaign.customThemeColors &&
    campaign.customThemeColors.length &&
    campaign.customThemeColors.find(
      cT => cT.name === CUSTOM_THEME_COLOR_NAMES.BASE
    ).colorCode;

  const customThemeImage =
    campaign &&
    campaign.customBranding &&
    campaign.customThemeImages &&
    campaign.customThemeImages.length;

  const customThemeLogoName =
    customThemeImage &&
    campaign.customThemeImages.find(
      cT => cT.name === CUSTOM_THEME_IMAGE_NAMES.LOGO
    ).name;

  const customThemeLogoImage =
    customThemeImage &&
    campaign.customThemeImages.find(
      cT => cT.name === CUSTOM_THEME_IMAGE_NAMES.LOGO
    ).imageFilename;

  const allowedToEditTranslations =
    campaign &&
    isTesting() &&
    clientId &&
    ((campaign.client && campaign.client === clientId) ||
      (campaign.collaborators &&
        campaign.collaborators.length &&
        campaign.collaborators.find(c => c.id === clientId)));

  let campaignLanguage = languages.DUTCH;
  if (result && result.userData && result.userData.language) {
    campaignLanguage = userLanguages[result.userData.language];
  } else if (campaign && campaign.language) {
    campaignLanguage = campaign.language;
  }

  const translationInputFieldProps = campaign
    ? {
        surveyId: campaign.id,
        clientId,
        language: campaignLanguage,
        disabled: !allowedToEditTranslations || savingTranslationError,
        testing: isTesting(),
        setSavingTranslationError
      }
    : {};

  const [termsAccepted, setTermsAccepted] = useState(false);
  const [termsDeclined, setTermsDeclined] = useState(false);
  const [viewTerms, setViewTerms] = useState(false);
  const [userEmail, setUserEmail] = useState(userEmailSuggestion || '');
  const [error, setError] = useState('');
  const [conversionStatus, setConversionStatus] = useState(null);
  const [conversionCompleted, setConversionCompleted] = useState(false);
  const [
    noExistingTermsAcceptedUser,
    setNoExistingTermsAcceptedUser
  ] = useState(false);

  const [getResult] = useLazyQuery(GET_RESULT, {
    fetchPolicy: 'network-only',
    onCompleted: response => {
      setCampaignComponents(
        response.result.result.survey,
        response.result.nextBlock,
        response.result.result
      );
      if (
        response.result.result.survey &&
        response.result.result.survey.language &&
        response.result.result.survey.language !== selectedLanguage
      ) {
        updateTranslationsLanguage(response.result.result.survey.language);
      }
    }
  });

  const displayMessageOnResponse = data => {
    if (
      data.errors &&
      data.errors.length &&
      data.errors.find(err => err.type === 'USER_HAS_EXISTING_RESULT')
    ) {
      setConversionStatus({
        text: conversionStatusTranslations.DUPLICATE_RESULT
      });
    } else if (!data.user) {
      setNoExistingTermsAcceptedUser(true);
    } else if (
      data.user &&
      (data.earnedTokens || data.earnedTokens === 0) &&
      (data.participatedSurveys || data.participatedSurveys === 0) &&
      communitySettings &&
      communitySettings.communityType &&
      communitySettings.communityType === communityTypes.MOBILE_APP
    ) {
      // This is when convert to MOBILE is selected
      const euroTokenFactor = 110;
      const variables = {
        '{EUROS}': (data.earnedTokens / euroTokenFactor).toFixed(2),
        '{SURVEYS}': data.participatedSurveys,
        '{EMAIL}': data.user.email,
        '{TOKENS}': data.earnedTokens
      };

      const translationsMapping = {
        pending: {
          [thankYouScreenConversionMessageOptionKeys.EUROS]:
            'END_OF_THE_SURVEY_THANK_YOU_SCREEN_PENDING_EUROS_CONVERSION',
          [thankYouScreenConversionMessageOptionKeys.TOKENS]:
            'END_OF_THE_SURVEY_THANK_YOU_SCREEN_PENDING_TOKENS_CONVERSION',
          [thankYouScreenConversionMessageOptionKeys.IMPACT]:
            'END_OF_THE_SURVEY_THANK_YOU_SCREEN_PENDING_IMPACT_CONVERSION',
          [thankYouScreenConversionMessageOptionKeys.CUSTOM]:
            'END_OF_THE_SURVEY_THANK_YOU_SCREEN_PENDING_CUSTOM_CONVERSION'
        },
        existing: {
          [thankYouScreenConversionMessageOptionKeys.EUROS]:
            'END_OF_THE_SURVEY_THANK_YOU_SCREEN_EXISTING_EUROS_CONVERSION',
          [thankYouScreenConversionMessageOptionKeys.TOKENS]:
            'END_OF_THE_SURVEY_THANK_YOU_SCREEN_EXISTING_TOKENS_CONVERSION',
          [thankYouScreenConversionMessageOptionKeys.IMPACT]:
            'END_OF_THE_SURVEY_THANK_YOU_SCREEN_EXISTING_IMPACT_CONVERSION',
          [thankYouScreenConversionMessageOptionKeys.CUSTOM]:
            'END_OF_THE_SURVEY_THANK_YOU_SCREEN_EXISTING_CUSTOM_CONVERSION'
        }
      };

      if (data.user.pendingActivation) {
        setConversionStatus({
          variables,
          text:
            translations[
              translationsMapping.pending[
                communitySettings.thankYouScreenConversionMessage
              ]
            ],
          pendingAccount: true
        });
      } else {
        setConversionStatus({
          variables,
          text:
            translations[
              translationsMapping.existing[
                communitySettings.thankYouScreenConversionMessage
              ]
            ]
        });
      }
    } else {
      // This is when convert to WEB is selected
      setConversionStatus({
        text: conversionStatusTranslations.CONSENT_FIRST_TIME
      });
    }
  };

  const [findAndRewardEmailConversionUser] = useMutation(
    FIND_AND_REWARD_EMAIL_CONVERSION_USER,
    {
      onCompleted: response => {
        if (response && response && response.findAndRewardEmailConversionUser) {
          displayMessageOnResponse(response.findAndRewardEmailConversionUser);
        }
        setConversionCompleted(true);
      }
    }
  );

  const [submitEmailConversion, { loading }] = useMutation(
    SUBMIT_EMAIL_CONVERSION,
    {
      onCompleted: response => {
        if (response && response && response.submitEmailConversion) {
          displayMessageOnResponse(response.submitEmailConversion);
        }
      }
    }
  );

  const { data: { getAppConfiguration } = {} } = useQuery(
    GET_APP_CONFIGURATION,
    {
      fetchPolicy: 'network-only'
    }
  );

  useEffect(() => {
    if (!campaign) {
      getResult({ variables: { id: match.params.resultId } });
    }
  }, []);

  useEffect(
    () => {
      if (
        campaign &&
        !(campaign.customCopies && campaign.customCopies.length)
      ) {
        updateTranslationsLanguage(campaignLanguage);
      }
      if (
        !customTranslationsLoaded &&
        campaign &&
        campaign.customCopies &&
        campaign.customCopies.length
      ) {
        setCustomTranslations(campaignLanguage, campaign.customCopies);
      }
      if (customThemeBaseColor) setCustomThemeBaseColor(customThemeBaseColor);
      if (customThemeImage) {
        setCustomThemeImage({
          NAME: customThemeLogoName,
          IMAGE: customThemeLogoImage
        });
      }
    },
    [campaign]
  );

  useEffect(
    () => {
      if (!conversionCompleted) {
        let email;
        const query = qs.parse(search);
        if (userEmailSuggestion && userEmailSuggestion.length) {
          email = userEmailSuggestion;
        } else if (
          query &&
          query.userEmailSuggestion &&
          query.userEmailSuggestion.length
        ) {
          email = query.userEmailSuggestion;
          setUserEmail(email);
        }

        if (email && email.length && !isTesting()) {
          findAndRewardEmailConversionUser({
            variables: {
              result: match.params.resultId,
              email
            }
          });
        } else {
          setNoExistingTermsAcceptedUser(true);
        }
      }
    },
    [userEmailSuggestion]
  );

  const onSubmitEmail = () => {
    if (error && error.length) {
      setError('');
    }
    if (!isTesting()) {
      submitEmailConversion({
        variables: {
          result: match.params.resultId,
          email: userEmail,
          language: campaignLanguage
        }
      });
    } else {
      setConversionStatus({
        text: conversionStatusTranslations.NO_CONSENT
      });
    }
  };

  const returnTranslationWithVariables = translation => {
    if (translation.variables) {
      const regex = new RegExp(
        Object.keys(translation.variables).join('|'),
        'gi'
      );
      const newTranslation = translation.text.replace(
        regex,
        matched => translation.variables[matched]
      );
      return newTranslation;
    }
    return translation.text;
  };

  const onClickNext = () => {
    if (termsAccepted) {
      if (userEmail && userEmail.length) {
        const regexTest = new RegExp(/^[^\s@]+@[^\s@]+\.[^\s@]+$/);
        if (regexTest.test(userEmail)) {
          onSubmitEmail();
        } else {
          setError(translations.PLEASE_ENTER_VALID_EMAIL);
        }
      } else {
        setError(translations.PLEASE_FILL_FIELD);
      }
    } else if (termsDeclined) {
      setConversionStatus({
        text: conversionStatusTranslations.NO_CONSENT
      });
    }
  };

  if (viewTerms) {
    const languageForTerms = campaignLanguage || 'NL';

    return (
      <TermsAndConditions
        termsContent={getAppConfiguration.legal.privacyPolicy}
        strings={strings}
        languages={languages}
        selectedLanguage={languageForTerms}
        setViewTerms={setViewTerms}
        customThemeBaseColor={customThemeBaseColor}
      />
    );
  }

  if (conversionStatus) {
    return (
      <div className={styles.parentContainer}>
        <div className={styles.mainContainer}>
          <div
            className={`${styles.contentContainer} ${
              !conversionStatus.pendingAccount
                ? styles.contentContainerMargin
                : ''
            }`}
          >
            <div className={styles.title}>
              {translations.CONVERSION_THANK_YOU}
            </div>
            <div className={styles.description}>
              {returnTranslationWithVariables(conversionStatus)}
            </div>
            {conversionStatus.pendingAccount && (
              <>
                <img
                  className={styles.qrCode}
                  src={QRCode}
                  alt="BUFFL app QR code"
                />
                <div className={styles.appStoreButtons}>
                  <a
                    target="_blank"
                    href="https://play.google.com/store/apps/details?id=org.nativescript.buffl"
                    rel="noopener noreferrer"
                  >
                    <img src={GooglePlayStore} alt="Google Play Store Button" />
                  </a>
                  <a
                    target="_blank"
                    href="https://itunes.apple.com/be/app/buffl/id1356715033?l=nl&mt=8"
                    rel="noopener noreferrer"
                  >
                    <img src={AppleStore} alt="App Store Button" />
                  </a>
                </div>
              </>
            )}
          </div>
        </div>
        <Footer
          hiddenNext
          onClickNext={false}
          onClickBack={false}
          customThemeBaseColor={customThemeBaseColor}
          hideIllustrationsInWebSurvey={
            campaign &&
            campaign.hideIllustrationsInWebSurvey &&
            campaign.customBranding
          }
          showBackgroundGrid={{
            showColor: !(campaign && campaign.customBranding)
          }}
        />
      </div>
    );
  }

  if (campaign && noExistingTermsAcceptedUser) {
    return (
      <div className={styles.parentContainer}>
        <div className={styles.mainContainer}>
          <div className={styles.contentContainer}>
            <div className={styles.title}>{translations.MORE_IMPACT}</div>
            <TranslationInputField
              name={customCopyFields.askForEmailAddressInWebSurvey.name}
              translationKey={giveAwayTranslation}
              value={translations[giveAwayTranslation]}
              customClasses={`${styles.description}`}
              {...translationInputFieldProps}
            />
            <div className={styles.inputContainer}>
              <input
                onChange={e => setUserEmail(e.target.value)}
                value={!termsDeclined ? userEmail : ''}
                className={`${styles.input} ${
                  error && error.length ? styles.errorField : ''
                }`}
                type="email"
                placeholder={translations.EMAIL}
                disabled={termsDeclined}
              />
              {error && error.length && (
                <div className={styles.errorContainer}>
                  <img
                    src={errorIcon}
                    alt="Error"
                    className={styles.errorIcon}
                  />
                  <span>{error}</span>
                </div>
              )}
            </div>
            <div className={styles.acceptInputContainer}>
              <AcceptTermsInput
                name="agree-to-tc"
                label={{
                  I_AM_AWARE_OF_THE: translations.I_AM_AWARE_OF_THE,
                  TERMS_AND_CONDITIONS: translations.TERMS_AND_CONDITIONS,
                  AND_AGREE_BY_CHECKING_THIS_BOX:
                    translations.AND_AGREE_BY_CHECKING_THIS_BOX,
                  AND_CONFIRM_I_AGREE: translations.AND_CONFIRM_I_AGREE,
                  I_AGREE: translations.I_AGREE
                }}
                active={termsAccepted}
                onClick={() => {
                  if (termsDeclined) setTermsDeclined(!termsDeclined);
                  setTermsAccepted(!termsAccepted);
                }}
                setViewTerms={setViewTerms}
                customThemeBaseColor={customThemeBaseColor}
              />
              <div className={styles.declineTermsContainer}>
                <AcceptTermsInput
                  name="decline-to-tc"
                  label={{
                    I_AM_AWARE_OF_THE: translations.I_AM_AWARE_OF_THE,
                    READ_AND_UNDERSTAND: translations.READ_AND_UNDERSTAND,
                    TERMS_AND_CONDITIONS: translations.TERMS_AND_CONDITIONS,
                    AND_AGREE_BY_CHECKING_THIS_BOX:
                      translations.AND_AGREE_BY_CHECKING_THIS_BOX,
                    AND_CONFIRM_I_AGREE: translations.AND_CONFIRM_I_AGREE,
                    I_AGREE: translations.I_AGREE,
                    I_AM_NOT_INTERESTED: translations.I_AM_NOT_INTERESTED,
                    CONFIRM: translations.CONFIRM
                  }}
                  active={termsDeclined}
                  onClick={() => {
                    if (termsAccepted) setTermsAccepted(!termsAccepted);
                    if (error) setError('');
                    setTermsDeclined(!termsDeclined);
                  }}
                  customThemeBaseColor={customThemeBaseColor}
                  declineTerms
                />
              </div>
            </div>
            <div
              className={styles.nextSelector}
              style={{
                backgroundColor: customThemeBaseColor || '#24B2E8',
                color: '#FFFFFF',
                opacity:
                  (!termsAccepted && !termsDeclined) || loading ? 0.3 : 1,
                cursor:
                  (!termsAccepted && !termsDeclined) || loading
                    ? 'default'
                    : 'pointer'
              }}
              role="presentation"
              onClick={() => {
                if (!loading) {
                  onClickNext();
                }
              }}
            >
              {translations.NEXT}
            </div>
          </div>
        </div>
        <Footer
          hiddenNext
          onClickNext={false}
          onClickBack={false}
          customThemeBaseColor={customThemeBaseColor}
          hideIllustrationsInWebSurvey={
            campaign &&
            campaign.hideIllustrationsInWebSurvey &&
            campaign.customBranding
          }
          showBackgroundGrid={{
            showColor: !(campaign && campaign.customBranding)
          }}
        />
      </div>
    );
  }
  return null;
};
