import React, { useMemo, useRef, useState, useEffect } from 'react';
import { useMutation } from '@apollo/react-hooks';

import {
  UPDATE_CONCEPT_TEST_GRAPH,
  REMOVE_CONCEPT_TEST_GRAPH
} from '../../../../../../../graphql/Survey';

import Bar from './components/Bar/Bar';
import FeaturesList from '../FeaturesList/FeaturesList';
import Legend from './components/Legend/Legend';
import GraphTitle from '../GraphTitle/GraphTitle';
import GraphInfoTabs from '../GraphInfoTabs/GraphInfoTabs';
import Disclaimer from '../Disclaimer/Disclaimer';
import GraphActionsToolbar from '../Toolbars/GraphActionsToolbar';
import LoversHaters from '../LoversHaters/LoversHaters';
import Branding from '../Branding/Branding';
import Popup from '../../../../../Popup';
import EditBackgroundPopup from '../EditBackgroundPopup/EditBackgroundPopup';

import calculateExtremeUsersData from '../../helpers/calculateExtremeUsersData';
import generateRandomColor from '../../helpers/generateRandomColor';
import setLoversHatersFilterEvent from '../../helpers/setLoversHatersFilterEvent';
import calculateChartData from '../../helpers/calculateChartData';
import updateGraphFeatures from '../../helpers/updateGraphFeatures';
import getAllowedQuestions from '../../helpers/getAllowedQuestions';
import onTooltipAdd from '../../helpers/charts/onTooltipAdd';
import onTooltipRemove from '../../helpers/charts/onTooltipRemove';
import onDataPointClick from '../../helpers/charts/onDataPointClick';
import {
  getDistributorFeatures,
  transformDistributorUnfilteredData
} from '../../helpers/handleDistributorData';

import { templateConfigurations, templateTypes } from '../../helpers/constants';
import createUnfilteredFeatures from '../../helpers/createUnfilteredFeatures';

import styles from '../MergedGraph/MergedGraph.module.css';

const graphInfoTabs = {
  LEGEND: 'Select data points',
  LOVERS_HATERS: 'Lovers/haters',
  DECISION: 'Decision',
  DISCLAIMER: 'Help'
};

const questionTypes = {
  xQuestion:
    templateConfigurations[templateTypes.CONCEPT_TEST_GRAPH].xQuestionName,
  yQuestion:
    templateConfigurations[templateTypes.CONCEPT_TEST_GRAPH].yQuestionName
};

export default ({
  graph,
  questions,
  distributorGroups,
  surveyId,
  setEditGraphsPopup,
  customGraphIndex,
  isFiltered,
  activeFilters,
  setDisplayContactInnovationExpertPopup,
  onToggleFilter,
  onSetFiltersRelation,
  isAllowedToExecuteRequests,
  defaultDataPointColors,
  viewToken,
  setShowAddInsightsPopup,
  triggerUpdate,
  isHidden,
  filteringOnSelection,
  setFilteringOnSelection,
  presentationModeEnabled,
  setPresentationModeEnabled
}) => {
  const [updateConceptTestGraph] = useMutation(UPDATE_CONCEPT_TEST_GRAPH);

  const [activegraphInfoTab, setActiveGraphInfoTab] = useState(
    graphInfoTabs.LEGEND
  );
  const [activeLoversHaters, setActiveLoversHaters] = useState();
  const [
    selectedExtremeUsersFeature,
    setSelectedExtremeUsersFeature
  ] = useState();
  const [
    selectedExtremeUsersFilter,
    setSelectedExtremeUsersFilter
  ] = useState();
  const [
    previousActiveLoversHaters,
    setPreviousActiveLoversHaters
  ] = useState();
  const [dataPointColors, setDataPointColors] = useState(
    defaultDataPointColors
  );
  const [themeColors, setThemeColors] = useState([
    {
      name: 'COLOR_1',
      colorCode: '#FFFBCC'
    },
    {
      name: 'COLOR_2',
      colorCode: '#C0E3D4'
    },
    {
      name: 'COLOR_3',
      colorCode: '#FFC3DA'
    },
    {
      name: 'COLOR_4',
      colorCode: '#FDD698'
    }
  ]);
  const [editBackgroundPopup, setEditBackgroundPopup] = useState(false);
  const [features, setFeatures] = useState([]);
  const [unFilteredFeatures, setUnFilteredFeatures] = useState([]);
  const [dataTooltips, setDataTooltips] = useState([]);
  const [prevInsightState, setPrevInsightState] = useState(null);

  const barParent = useRef();

  const [removeGraph, { loading: removeGraphLoading }] = useMutation(
    REMOVE_CONCEPT_TEST_GRAPH
  );

  const onRemoveGraphClick = async () => {
    if (isAllowedToExecuteRequests && !removeGraphLoading) {
      await removeGraph({
        variables: {
          survey: surveyId,
          id: graph.id,
          ...(viewToken ? { viewToken } : {})
        }
      });

      if (activeFilters && activeFilters.length) {
        if (graph && graph.name) {
          const newFilters = activeFilters.find(
            f => f.filterName === graph.name
          );
          if (newFilters) {
            onToggleFilter(
              newFilters.filterName,
              newFilters.valuePath,
              newFilters.value,
              newFilters.resultsPath
            );
          }
        }
      }
    }
  };

  const getExtremeUsersData = () => {
    const extremeUsersResultIds = calculateExtremeUsersData(
      activeLoversHaters,
      reducedQuestions,
      questionTypes
    );

    if (!activeFilters.length) {
      onSetFiltersRelation('or');
    } else if (
      previousActiveLoversHaters &&
      (activeFilters.length &&
        activeFilters.find(
          filter =>
            filter.value === previousActiveLoversHaters &&
            filter.filterName === graph.name
        ))
    ) {
      // Remove existing filter when selecting different one in dropdown
      onToggleFilter(graph.name, null, previousActiveLoversHaters, null, false);
    }
    setPreviousActiveLoversHaters(activeLoversHaters.filter);
    onToggleFilter(
      graph.name,
      null,
      activeLoversHaters.filter,
      null,
      result => {
        if (
          result &&
          result.id &&
          extremeUsersResultIds.indexOf(result.id) > -1
        ) {
          return true;
        }
        return false;
      },
      `${activeLoversHaters.id}`
    );
    setLoversHatersFilterEvent(activeLoversHaters.filter, 'CONCEPT_TEST');
  };

  const getChartData = useUnfilteredResultStats => {
    if (
      features &&
      features.length &&
      reducedQuestions &&
      reducedQuestions.length
    ) {
      return calculateChartData(
        features,
        unFilteredFeatures,
        useUnfilteredResultStats,
        questionTypes,
        reducedQuestions,
        filteringOnSelection
      );
    }
    return [];
  };

  const reducedQuestions = useMemo(() => getAllowedQuestions(questions), [
    questions
  ]);

  const data = useMemo(() => getChartData(false), [
    questions,
    JSON.stringify(features),
    filteringOnSelection,
    activeFilters.length
  ]);
  const unFilteredData = useMemo(() => getChartData(true), [
    JSON.stringify(unFilteredFeatures),
    filteringOnSelection,
    activeFilters.length
  ]);
  const activeLoversHatersFeatures = useMemo(
    () =>
      getDistributorFeatures(
        activeLoversHaters,
        distributorGroups,
        questionTypes,
        features
      ),
    [activeLoversHaters]
  );

  useEffect(
    () => {
      if (
        activeLoversHaters &&
        activeLoversHaters.filter &&
        activeLoversHaters.xQuestion &&
        activeLoversHaters.yQuestion
      ) {
        getExtremeUsersData();
      }
    },
    [activeLoversHaters, graph.features.length]
  );

  useEffect(
    () => {
      // On removal of filter in FiltersBar component
      let needToUpdate = false;
      if (activeLoversHaters && activeLoversHaters.filter) {
        if (
          !activeFilters.length ||
          (activeFilters.length &&
            activeFilters.findIndex(
              filter =>
                filter.value === activeLoversHaters.filter &&
                filter.filterName === graph.name
            ) === -1)
        ) {
          needToUpdate = true;
        }
      }
      if (needToUpdate) {
        setActiveLoversHaters(null);
      }
    },
    [activeFilters]
  );

  useEffect(
    () => {
      // Replace default data point color with custom defined
      if (features) {
        let dirty = false;
        const newDataPointColors = [...dataPointColors];
        features.forEach((fG, i) => {
          if (fG.colorCode) {
            dirty = true;
            newDataPointColors[i] = fG.colorCode;
          }
        });

        if (dirty) {
          setDataPointColors(newDataPointColors);
        }
      }

      if (data && data.length && data.length > dataPointColors.length) {
        const replenishedColors = [...dataPointColors];
        const dataLength = data.length;
        const defaultDataPointColorsLength = dataPointColors.length;
        for (let i = 0; i < dataLength - defaultDataPointColorsLength; i += 1) {
          replenishedColors.push(generateRandomColor());
        }
        setDataPointColors(replenishedColors);
      }
    },
    [data]
  );

  useEffect(
    () => {
      if (graph && graph.customThemeColors && graph.customThemeColors.length) {
        setThemeColors(graph.customThemeColors);
      }
      if (
        graph &&
        graph.features &&
        graph.features.length &&
        !features.length
      ) {
        setFeatures(graph.features);
      }
    },
    [graph]
  );

  useEffect(
    () => {
      if (triggerUpdate && graph) {
        if (triggerUpdate.deriveFrom === 'prevState') {
          setFeatures(prevInsightState.features.map(f => ({ ...f })));
          setUnFilteredFeatures(
            prevInsightState.unFilteredFeatures.map(uF => ({ ...uF }))
          );
        } else {
          // Set prevState to display when unselecting insight
          setPrevInsightState({
            features: features.map(f => ({ ...f })),
            unFilteredFeatures: unFilteredFeatures.map(uF => ({ ...uF }))
          });

          if (graph.features && graph.features.length) {
            setFeatures(graph.features);
          }
          if (
            isFiltered &&
            graph.unFilteredFeatures &&
            graph.unFilteredFeatures.length
          ) {
            // Derive other info from features but use unfiltered Ids
            setUnFilteredFeatures(
              graph.unFilteredFeatures.map((uF, i) => ({
                ...(graph.features && graph.features.length && graph.features[i]
                  ? graph.features[i]
                  : {}),
                ...uF
              }))
            );
          }
        }
      }
    },
    [triggerUpdate]
  );

  const activeStateHasChanged = (arr1, arr2) => {
    if (arr1.length !== arr2.length) return true;

    return arr1.reduce((mismatchFound, item1, index) => {
      if (mismatchFound) return true;

      const item2 = arr2[index];
      return item1.active !== item2.active;
    }, false);
  };

  useEffect(
    () => {
      if (features.length && isFiltered && !unFilteredFeatures.length) {
        // Set unfiltered features when activating filter
        const unFilteredFeaturesToCreate = createUnfilteredFeatures(
          graph,
          features
        );
        setUnFilteredFeatures(unFilteredFeaturesToCreate);

        // Set unFilteredFeatures in survey if they dont exist or have changed
        if (
          isAllowedToExecuteRequests &&
          (!(
            graph &&
            graph.unFilteredFeatures &&
            graph.unFilteredFeatures.length
          ) ||
            (graph &&
              graph.unFilteredFeatures &&
              graph.unFilteredFeatures.length &&
              activeStateHasChanged(
                graph.unFilteredFeatures,
                unFilteredFeaturesToCreate
              )))
        ) {
          updateGraphFeatures(
            graph.id,
            surveyId,
            unFilteredFeaturesToCreate,
            updateConceptTestGraph,
            viewToken || null,
            true
          );
        }
      } else if (unFilteredFeatures.length && !isFiltered) {
        setUnFilteredFeatures([]);
      }
    },
    [isFiltered, features]
  );

  if (isHidden) return null;

  return (
    <div className={`${styles.container} custom-graph-container`}>
      <GraphActionsToolbar
        onEdit={() => {
          setEditGraphsPopup({
            ...graph,
            features: [...features],
            index: customGraphIndex,
            type: templateTypes.CONCEPT_TEST_GRAPH
          });
        }}
        onRemove={onRemoveGraphClick}
        filteringOnSelection={filteringOnSelection}
        setFilteringOnSelection={setFilteringOnSelection}
        isFiltered={isFiltered}
        setEditBackgroundPopup={setEditBackgroundPopup}
        presentationModeEnabled={presentationModeEnabled}
        setPresentationModeEnabled={setPresentationModeEnabled}
        graph={graph}
        setShowAddInsightsPopup={setShowAddInsightsPopup}
        viewToken={viewToken}
      />
      <GraphTitle
        graphName={(graph && graph.name) || ''}
        data={data}
        questionsHaveResults={
          reducedQuestions &&
          reducedQuestions.length &&
          reducedQuestions.some(
            rQ => rQ.unfilteredResults && rQ.unfilteredResults.length
          )
        }
      />
      <GraphInfoTabs
        graphInfoTabs={graphInfoTabs}
        activegraphInfoTab={activegraphInfoTab}
        setActiveGraphInfoTab={setActiveGraphInfoTab}
      />
      <div
        className={`${styles.blockContent} ${styles.opportunityGraphContent}`}
      >
        {features && features.length ? (
          <>
            <div className={styles.opportunityChartContainer} ref={barParent}>
              <Bar
                parent={barParent}
                width="460"
                height="430"
                data={data}
                unFilteredData={
                  activeLoversHatersFeatures &&
                  activeLoversHatersFeatures.length
                    ? transformDistributorUnfilteredData(
                        data,
                        unFilteredData,
                        activeLoversHatersFeatures
                      )
                    : unFilteredData
                }
                isFiltered={!!activeFilters.length}
                dataPointColors={dataPointColors.filter(
                  (_dataPoint, index) =>
                    features[index] && features[index].active
                )}
                unFilteredDataPointColors={dataPointColors.filter(
                  (_dataPoint, index) =>
                    unFilteredFeatures[index] &&
                    unFilteredFeatures[index].active
                )}
                questions={questions}
                activeLoversHaters={activeLoversHaters}
                themeColors={themeColors}
                xLabel={
                  graph.xLabel && graph.xLabel.trim().length
                    ? graph.xLabel
                    : null
                }
                yLabel={
                  graph.yLabel && graph.yLabel.trim().length
                    ? graph.yLabel
                    : null
                }
                dataTooltips={dataTooltips}
                setDataTooltips={setDataTooltips}
                onTooltipAdd={onTooltipAdd}
                onTooltipRemove={onTooltipRemove}
                onDataPointClick={onDataPointClick}
              />
            </div>
            <div className={styles.graphInfoContainer}>
              {activegraphInfoTab === graphInfoTabs.LEGEND && (
                <FeaturesList
                  features={features}
                  setFeatures={setFeatures}
                  dataPointColors={dataPointColors}
                  setDataPointColors={setDataPointColors}
                  saveUpdatedFeatures={(updatedFeatures, unfiltered) => {
                    if (isAllowedToExecuteRequests) {
                      updateGraphFeatures(
                        graph.id,
                        surveyId,
                        updatedFeatures,
                        updateConceptTestGraph,
                        viewToken || null,
                        unfiltered
                      );
                    }
                  }}
                  questions={reducedQuestions}
                  questionTypes={questionTypes}
                  distributorGroups={distributorGroups}
                  isFiltered={!!activeFilters.length}
                  unFilteredFeatures={unFilteredFeatures}
                  setUnFilteredFeatures={setUnFilteredFeatures}
                  filteringOnSelection={filteringOnSelection}
                  activeFiltersLength={activeFilters && activeFilters.length}
                  templateType={templateTypes.CONCEPT_TEST_GRAPH}
                  presentationModeEnabled={presentationModeEnabled}
                  onTooltipRemove={i => {
                    if (dataTooltips.some(t => t.index === i)) {
                      onTooltipRemove(setDataTooltips, i);
                    }
                  }}
                  activeLoversHatersFeatures={activeLoversHatersFeatures}
                  triggerUpdate={triggerUpdate}
                />
              )}
              {activegraphInfoTab === graphInfoTabs.DECISION && (
                <Legend themeColors={themeColors} />
              )}
              {activegraphInfoTab === graphInfoTabs.LOVERS_HATERS && (
                <LoversHaters
                  activeLoversHaters={activeLoversHaters}
                  setActiveLoversHaters={setActiveLoversHaters}
                  features={features}
                  dataPointColors={dataPointColors}
                  selectedFeature={selectedExtremeUsersFeature}
                  setSelectedFeature={setSelectedExtremeUsersFeature}
                  selectedFilter={selectedExtremeUsersFilter}
                  setSelectedFilter={setSelectedExtremeUsersFilter}
                  explanation="When trying to figure out which concepts to address, it can be helpful to focus on
                  respondents who are most enthusiastic about a particular concept and will thus be the promotors.
                  What other concepts do they find important and what characteristics does this group have?"
                />
              )}
              {activegraphInfoTab === graphInfoTabs.BRANDING && (
                <Branding
                  updateGraphMutation={updateConceptTestGraph}
                  graphId={graph.id}
                  features={features}
                  setFeatures={setFeatures}
                  surveyId={surveyId}
                  defaultDataPointColors={defaultDataPointColors}
                  themeColors={themeColors}
                  setThemeColors={setThemeColors}
                  dataPointColors={dataPointColors}
                  setDataPointColors={setDataPointColors}
                  selectedExtremeUsersFeature={selectedExtremeUsersFeature}
                  setSelectedExtremeUsersFeature={
                    setSelectedExtremeUsersFeature
                  }
                  questionTypes={questionTypes}
                  isAllowedToExecuteRequests={isAllowedToExecuteRequests}
                  viewToken={viewToken}
                />
              )}
              {activegraphInfoTab === graphInfoTabs.DISCLAIMER && (
                <Disclaimer
                  setDisplayContactInnovationExpertPopup={
                    setDisplayContactInnovationExpertPopup
                  }
                  isAllowedToExecuteRequests={isAllowedToExecuteRequests}
                />
              )}
            </div>
          </>
        ) : (
          <div className={`${styles.noData} no-chart-data`}>No data</div>
        )}
      </div>
      {editBackgroundPopup && (
        <Popup
          component={
            <EditBackgroundPopup
              onClose={() => setEditBackgroundPopup(false)}
              templateType={templateTypes.CONCEPT_TEST_GRAPH}
              templateTypes={templateTypes}
              themeColors={themeColors}
              onSaveGraph={newThemeColors => {
                setThemeColors(newThemeColors);
                if (isAllowedToExecuteRequests) {
                  updateConceptTestGraph({
                    variables: {
                      id: graph.id,
                      survey: surveyId,
                      customThemeColors: newThemeColors,
                      ...(viewToken ? { viewToken } : {})
                    }
                  });
                }
              }}
            />
          }
          customStyles={{ padding: 'none', overflow: 'visible' }}
          onClose={() => setEditBackgroundPopup(false)}
        />
      )}
    </div>
  );
};
