import React, { useState, useContext } from 'react';

import * as mixpanel from '../../../../../../../../../../mixpanel';

import TemplatesWizardContext from '../../../TemplatesWizardContext';
import InfoPopup from './components/InfoPopup/InfoPopup';
import Footer from '../../Footer/Footer';
import Popup from '../../../../Popup/Popup';

import problem from '../../../../../../../../../assets/img/templateswizard/problem.svg';
import idea from '../../../../../../../../../assets/img/templateswizard/idea.svg';
import value from '../../../../../../../../../assets/img/templateswizard/value.svg';
import concept from '../../../../../../../../../assets/img/templateswizard/concept.svg';

import {
  TEMPLATES_WIZARD_STEP_KEYS,
  TEMPLATES_WIZARD_TEMPLATE_TYPES
} from '../../../../../helpers/constants';

import styles from './SelectTemplateType.module.css';
import { checkIfAllCookiesAllowed } from '../../../../../../../../../helpers/privacy';

export default () => {
  const {
    currentStep,
    setStepValue,
    goToNextStep,
    goToPreviousStep,
    steps,
    resetWizartInputData,
    strings,
    surveyId
  } = useContext(TemplatesWizardContext);
  const [infoPopupTemplateType, setInfoPopupTemplateType] = useState(null);

  const onNextClick = () => {
    let defaultCount;
    switch (currentStep.value) {
      case TEMPLATES_WIZARD_TEMPLATE_TYPES.PROBLEM_VALIDATION:
        defaultCount = 3;
        break;
      case TEMPLATES_WIZARD_TEMPLATE_TYPES.IDEA_SELECTION:
        defaultCount = 4;
        break;
      case TEMPLATES_WIZARD_TEMPLATE_TYPES.VALUE_SELECTION:
        defaultCount = 3;
        break;
      case TEMPLATES_WIZARD_TEMPLATE_TYPES.CONCEPT_TEST:
        defaultCount = 1;
        break;
      default:
        defaultCount = 1;
    }

    setStepValue(TEMPLATES_WIZARD_STEP_KEYS.SELECT_TESTS_NUMBER, defaultCount);

    goToNextStep();
  };

  const onSelectTemplateType = type => {
    setStepValue(TEMPLATES_WIZARD_STEP_KEYS.SELECT_TEMPLATE_TYPE, type);

    const defineTemplateTypeStep = steps.find(
      s => s.key === TEMPLATES_WIZARD_STEP_KEYS.SELECT_TEMPLATE_TYPE
    );
    if (
      !defineTemplateTypeStep.value ||
      defineTemplateTypeStep.value !== value
    ) {
      resetWizartInputData();
    }
  };

  const mixpanelWhenOrHowToUseEvent = type => {
    if (checkIfAllCookiesAllowed()) {
      mixpanel.actions.trackEvent(
        mixpanel.events.CLIENT_CLICK_WHEN_OR_HOW_TO_USE_IN_SURVEY_TEMPLATES,
        {
          [mixpanel.eventProperties.SURVEY_ID]: surveyId,
          [mixpanel.eventProperties.DECISION_FRAMEWORK_TYPE]: type
        }
      );
    }
  };

  return (
    <div className={styles.container}>
      <div className={styles.title}>{strings.WHAT_TEMPLATE_WOULD_YOU_USE}</div>
      <div className={styles.optionsContainer}>
        <div className={styles.optionWrapper}>
          <div
            className={styles.option}
            onClick={() =>
              onSelectTemplateType(
                TEMPLATES_WIZARD_TEMPLATE_TYPES.PROBLEM_VALIDATION
              )
            }
            role="presentation"
          >
            <img src={problem} alt="Problem validation" />
            <div className={styles.optionLabel}>
              {strings.PROBLEM_VALIDATION_LABEL}
            </div>
            {currentStep &&
              currentStep.value ===
                TEMPLATES_WIZARD_TEMPLATE_TYPES.PROBLEM_VALIDATION && (
                <div className={styles.selectedBar} />
              )}
          </div>
          <div
            className={styles.optionInfo}
            onClick={() => {
              setInfoPopupTemplateType(
                TEMPLATES_WIZARD_TEMPLATE_TYPES.PROBLEM_VALIDATION
              );
              mixpanelWhenOrHowToUseEvent(
                TEMPLATES_WIZARD_TEMPLATE_TYPES.PROBLEM_VALIDATION
              );
            }}
            role="presentation"
          >
            {strings.WHEN_OR_HOW_TO_USE}
          </div>
        </div>
        <div className={styles.optionWrapper}>
          <div
            className={styles.option}
            onClick={() =>
              onSelectTemplateType(
                TEMPLATES_WIZARD_TEMPLATE_TYPES.IDEA_SELECTION
              )
            }
            role="presentation"
          >
            <img src={idea} alt="Idea selection" />
            <div className={styles.optionLabel}>
              {strings.IDEA_SELECTION_LABEL}
            </div>
            {currentStep &&
              currentStep.value ===
                TEMPLATES_WIZARD_TEMPLATE_TYPES.IDEA_SELECTION && (
                <div className={styles.selectedBar} />
              )}
          </div>
          <div
            className={styles.optionInfo}
            onClick={() => {
              setInfoPopupTemplateType(
                TEMPLATES_WIZARD_TEMPLATE_TYPES.IDEA_SELECTION
              );
              mixpanelWhenOrHowToUseEvent(
                TEMPLATES_WIZARD_TEMPLATE_TYPES.IDEA_SELECTION
              );
            }}
            role="presentation"
          >
            {strings.WHEN_OR_HOW_TO_USE}
          </div>
        </div>
        <div className={styles.optionWrapper}>
          <div
            className={styles.option}
            onClick={() =>
              onSelectTemplateType(
                TEMPLATES_WIZARD_TEMPLATE_TYPES.VALUE_SELECTION
              )
            }
            role="presentation"
          >
            <img src={value} alt="Value selection" />
            <div className={styles.optionLabel}>
              {strings.VALUE_SELECTION_LABEL}
            </div>
            {currentStep &&
              currentStep.value ===
                TEMPLATES_WIZARD_TEMPLATE_TYPES.VALUE_SELECTION && (
                <div className={styles.selectedBar} />
              )}
          </div>
          <div
            className={styles.optionInfo}
            onClick={() => {
              setInfoPopupTemplateType(
                TEMPLATES_WIZARD_TEMPLATE_TYPES.VALUE_SELECTION
              );
              mixpanelWhenOrHowToUseEvent(
                TEMPLATES_WIZARD_TEMPLATE_TYPES.VALUE_SELECTION
              );
            }}
            role="presentation"
          >
            {strings.WHEN_OR_HOW_TO_USE}
          </div>
        </div>
        <div className={styles.optionWrapper}>
          <div
            className={styles.option}
            onClick={() =>
              onSelectTemplateType(TEMPLATES_WIZARD_TEMPLATE_TYPES.CONCEPT_TEST)
            }
            role="presentation"
          >
            <img src={concept} alt="Concept test" />
            <div className={styles.optionLabel}>
              {strings.CONCEPT_TEST_LABEL}
            </div>
            {currentStep &&
              currentStep.value ===
                TEMPLATES_WIZARD_TEMPLATE_TYPES.CONCEPT_TEST && (
                <div className={styles.selectedBar} />
              )}
          </div>
          <div
            className={styles.optionInfo}
            onClick={() => {
              setInfoPopupTemplateType(
                TEMPLATES_WIZARD_TEMPLATE_TYPES.CONCEPT_TEST
              );
              mixpanelWhenOrHowToUseEvent(
                TEMPLATES_WIZARD_TEMPLATE_TYPES.CONCEPT_TEST
              );
            }}
            role="presentation"
          >
            {strings.WHEN_OR_HOW_TO_USE}
          </div>
        </div>
      </div>
      <div className={styles.info}>
        {strings.WOULD_YOU_LIKE_CUSTOM_TEMPLATE}{' '}
        <a href="mailto:seppe.stroo@buffl.be">seppe.stroo@buffl.be</a>
      </div>
      <div className={styles.footerContainer}>
        <Footer
          onNextClick={onNextClick}
          onPreviousClick={goToPreviousStep}
          nextLabel={strings.NEXT}
          previousLabel={strings.PREVIOUS}
        />
      </div>
      {infoPopupTemplateType && (
        <Popup
          component={
            <InfoPopup
              infoPopupTemplateType={infoPopupTemplateType}
              setInfoPopupTemplateType={setInfoPopupTemplateType}
            />
          }
          onClose={() => setInfoPopupTemplateType(null)}
        />
      )}
    </div>
  );
};
